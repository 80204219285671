export const FETCH_PROPERTIES_LIST_START = 'FETCH_PROPERTIES_LIST_START';
export const FETCH_PROPERTIES_LIST_SUCCESS = 'FETCH_PROPERTIES_LIST_SUCCESS';
export const FETCH_PROPERTIES_LIST_FAIL = 'FETCH_PROPERTIES_LIST_FAIL';

export const FETCH_ALL_PROPERTIES_START = 'FETCH_ALL_PROPERTIES_START';
export const FETCH_ALL_PROPERTIES_SUCCESS = 'FETCH_ALL_PROPERTIES_SUCCESS';
export const FETCH_ALL_PROPERTIES_FAIL = 'FETCH_ALL_PROPERTIES_FAIL';

export const FETCH_PROPERTY_START = 'FETCH_PROPERTY_START';
export const FETCH_PROPERTY_SUCCESS = 'FETCH_PROPERTY_SUCCESS';
export const FETCH_PROPERTY_FAIL = 'FETCH_PROPERTY_FAIL';

export const CREATE_PROPERTY_START = 'CREATE_PROPERTY_START';
export const CREATE_PROPERTY_SUCCESS = 'CREATE_PROPERTY_SUCCESS';
export const CREATE_PROPERTY_FAIL = 'CREATE_PROPERTY_FAIL';

export const UPDATE_PROPERTY_START = 'UPDATE_PROPERTY_START';
export const UPDATE_PROPERTY_SUCCESS = 'UPDATE_PROPERTY_SUCCESS';
export const UPDATE_PROPERTY_FAIL = 'UPDATE_PROPERTY_FAIL';

export const DELETE_PROPERTY_START = 'DELETE_PROPERTY_START';
export const DELETE_PROPERTY_SUCCESS = 'DELETE_PROPERTY_SUCCESS';
export const DELETE_PROPERTY_FAIL = 'DELETE_PROPERTY_FAIL';

export const FETCH_BUILDING_TYPE_PROPERTY_START =
  'FETCH_BUILDING_TYPE_PROPERTY_START';
export const FETCH_BUILDING_TYPE_PROPERTY_SUCCESS =
  'FETCH_BUILDING_TYPE_PROPERTY_SUCCESS';
export const FETCH_BUILDING_TYPE_PROPERTY_FAIL =
  'FETCH_BUILDING_TYPE_PROPERTY_FAIL';

export const RESET_CREATED_PROPERTY_STORE = 'RESET_CREATED_PROPERTY';
export const RESET_PROPERTY_STORE = 'RESET_PROPERTY_STORE';

export const LOGOUT = 'LOGOUT';

export type PropertyActionTypes =
  | typeof FETCH_PROPERTIES_LIST_START
  | typeof FETCH_PROPERTIES_LIST_SUCCESS
  | typeof FETCH_PROPERTIES_LIST_FAIL
  | typeof FETCH_ALL_PROPERTIES_START
  | typeof FETCH_ALL_PROPERTIES_SUCCESS
  | typeof FETCH_ALL_PROPERTIES_FAIL
  | typeof FETCH_PROPERTY_START
  | typeof FETCH_PROPERTY_SUCCESS
  | typeof FETCH_PROPERTY_FAIL
  | typeof CREATE_PROPERTY_START
  | typeof CREATE_PROPERTY_SUCCESS
  | typeof CREATE_PROPERTY_FAIL
  | typeof UPDATE_PROPERTY_START
  | typeof UPDATE_PROPERTY_SUCCESS
  | typeof UPDATE_PROPERTY_FAIL
  | typeof FETCH_BUILDING_TYPE_PROPERTY_START
  | typeof FETCH_BUILDING_TYPE_PROPERTY_SUCCESS
  | typeof FETCH_BUILDING_TYPE_PROPERTY_FAIL
  | typeof DELETE_PROPERTY_START
  | typeof DELETE_PROPERTY_SUCCESS
  | typeof DELETE_PROPERTY_FAIL
  | typeof RESET_CREATED_PROPERTY_STORE
  | typeof RESET_PROPERTY_STORE
  | typeof LOGOUT;
