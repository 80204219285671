import * as actionTypes from './actionTypes';
import { RemoteLocationActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { RemoteLocation } from '../../domain/RemoteLocation';

export type RemoteLocationStateType = {
  remoteLocationsList: ListResults<RemoteLocation> | null;
  remoteLocationsListLoading: boolean;
  remoteLocationsListError: HttpError;
  remoteLocationsListUpdateNeeded: boolean;
  remoteLocationCreateLoading: boolean;
  remoteLocationCreateSuccess: boolean;
  createdRemoteLocation: RemoteLocation | null;
  remoteLocationCreateError: HttpError;
  remoteLocationAssignLocationLoading: boolean;
  remoteLocationAssignLocationError: HttpError;
  remoteLocationAssignLocationSuccess: boolean;
  remoteLocationChangeStatusRejectLoading: boolean;
  remoteLocationChangeStatusRejectError: HttpError;
  remoteLocationChangeStatusRejectSuccess: boolean;
  remoteLocationChangeStatusAssignLoading: boolean;
  remoteLocationChangeStatusAssignError: HttpError;
  remoteLocationChangeStatusAssignSuccess: boolean;
  locationRemoteLocationsLoading: boolean;
  locationRemoteLocations: ListResults<RemoteLocation> | null;
  locationRemoteLocationsError: HttpError;
};

export type RemoteLocationActionType = RemoteLocationStateType & {
  type: RemoteLocationActionTypes;
};

export const initialState: RemoteLocationStateType = {
  remoteLocationsList: null,
  remoteLocationsListLoading: true,
  remoteLocationsListError: null,
  remoteLocationsListUpdateNeeded: false,
  remoteLocationCreateLoading: false,
  remoteLocationCreateSuccess: false,
  createdRemoteLocation: null,
  remoteLocationCreateError: null,
  remoteLocationAssignLocationLoading: false,
  remoteLocationAssignLocationError: null,
  remoteLocationAssignLocationSuccess: false,
  remoteLocationChangeStatusRejectLoading: false,
  remoteLocationChangeStatusRejectError: null,
  remoteLocationChangeStatusRejectSuccess: false,
  remoteLocationChangeStatusAssignLoading: false,
  remoteLocationChangeStatusAssignError: null,
  remoteLocationChangeStatusAssignSuccess: false,
  locationRemoteLocationsLoading: false,
  locationRemoteLocations: null,
  locationRemoteLocationsError: null,
};

const fetchRemoteLocationListStart = (
  state: RemoteLocationStateType,
): RemoteLocationStateType => ({
  ...state,
  remoteLocationsListLoading: true,
});

const fetchRemoteLocationListSuccess = (
  state: RemoteLocationStateType,
  action: RemoteLocationActionType,
): RemoteLocationStateType => ({
  ...state,
  remoteLocationsListLoading: false,
  remoteLocationsList: action.remoteLocationsList,
  remoteLocationsListUpdateNeeded: false,
});

const fetchRemoteLocationListFail = (
  state: RemoteLocationStateType,
  action: RemoteLocationActionType,
): RemoteLocationStateType => ({
  ...state,
  remoteLocationsListLoading: false,
  remoteLocationsListError: action.remoteLocationsListError,
});

const createRemoteLocationStart = (
  state: RemoteLocationStateType,
): RemoteLocationStateType => ({
  ...state,
  remoteLocationCreateLoading: true,
});

const createRemoteLocationSuccess = (
  state: RemoteLocationStateType,
  action: RemoteLocationActionType,
): RemoteLocationStateType => ({
  ...state,
  remoteLocationCreateLoading: false,
  remoteLocationCreateSuccess: true,
  createdRemoteLocation: action.createdRemoteLocation,
  remoteLocationsListUpdateNeeded: true,
});

const createRemoteLocationFail = (
  state: RemoteLocationStateType,
  action: RemoteLocationActionType,
): RemoteLocationStateType => ({
  ...state,
  remoteLocationCreateLoading: false,
  remoteLocationCreateError: action.remoteLocationCreateError,
});

const assignLocationRemoteLocationStart = (
  state: RemoteLocationStateType,
): RemoteLocationStateType => ({
  ...state,
  remoteLocationAssignLocationLoading: true,
});

const assignLocationRemoteLocationSuccess = (
  state: RemoteLocationStateType,
): RemoteLocationStateType => ({
  ...state,
  remoteLocationAssignLocationLoading: false,
  remoteLocationAssignLocationSuccess: true,
  remoteLocationsListUpdateNeeded: true,
});

const assignLocationRemoteLocationFail = (
  state: RemoteLocationStateType,
  action: RemoteLocationActionType,
): RemoteLocationStateType => ({
  ...state,
  remoteLocationAssignLocationLoading: false,
  remoteLocationAssignLocationError: action.remoteLocationAssignLocationError,
});

const remoteLocationChangeStatusAssignStart = (
  state: RemoteLocationStateType,
): RemoteLocationStateType => ({
  ...state,
  remoteLocationChangeStatusAssignLoading: true,
});

const remoteLocationChangeStatusAssignSuccess = (
  state: RemoteLocationStateType,
): RemoteLocationStateType => ({
  ...state,
  remoteLocationChangeStatusAssignLoading: false,
  remoteLocationChangeStatusAssignSuccess: true,
  remoteLocationsListUpdateNeeded: true,
});

const remoteLocationChangeStatusAssignFail = (
  state: RemoteLocationStateType,
  action: RemoteLocationActionType,
): RemoteLocationStateType => ({
  ...state,
  remoteLocationChangeStatusAssignLoading: false,
  remoteLocationChangeStatusAssignError:
    action.remoteLocationChangeStatusAssignError,
});

const remoteLocationChangeStatusRejectStart = (
  state: RemoteLocationStateType,
): RemoteLocationStateType => ({
  ...state,
  remoteLocationChangeStatusRejectLoading: true,
});

const remoteLocationChangeStatusRejectSuccess = (
  state: RemoteLocationStateType,
): RemoteLocationStateType => ({
  ...state,
  remoteLocationChangeStatusRejectLoading: false,
  remoteLocationChangeStatusRejectSuccess: true,
  remoteLocationsListUpdateNeeded: true,
});

const remoteLocationChangeStatusRejectFail = (
  state: RemoteLocationStateType,
  action: RemoteLocationActionType,
): RemoteLocationStateType => ({
  ...state,
  remoteLocationChangeStatusRejectLoading: false,
  remoteLocationChangeStatusRejectError:
    action.remoteLocationChangeStatusRejectError,
});

const fetchLocationRemoteLocationsStart = (
  state: RemoteLocationStateType,
): RemoteLocationStateType => ({
  ...state,
  locationRemoteLocationsLoading: true,
});

const fetchLocationRemoteLocationsSuccess = (
  state: RemoteLocationStateType,
  action: RemoteLocationActionType,
): RemoteLocationStateType => ({
  ...state,
  locationRemoteLocationsLoading: false,
  locationRemoteLocations: action.locationRemoteLocations,
  remoteLocationsListUpdateNeeded: false,
});

const fetchLocationRemoteLocationsFail = (
  state: RemoteLocationStateType,
  action: RemoteLocationActionType,
): RemoteLocationStateType => ({
  ...state,
  locationRemoteLocationsLoading: false,
  locationRemoteLocationsError: action.locationRemoteLocationsError,
});

const resetCreatedRemoteLocationStore = (
  state: RemoteLocationStateType,
): RemoteLocationStateType => ({
  ...state,
  remoteLocationCreateLoading: false,
  remoteLocationCreateError: null,
  remoteLocationCreateSuccess: false,
  remoteLocationsListUpdateNeeded: false,
  createdRemoteLocation: null,
});

const resetRemoteLocationStore = (): RemoteLocationStateType => ({
  ...initialState,
});

const completelyResetRemoteLocationStore = (): RemoteLocationStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: RemoteLocationActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_REMOTE_LOCATIONS_LIST_START:
      return fetchRemoteLocationListStart(state);
    case actionTypes.FETCH_REMOTE_LOCATIONS_LIST_SUCCESS:
      return fetchRemoteLocationListSuccess(state, action);
    case actionTypes.FETCH_REMOTE_LOCATIONS_LIST_FAIL:
      return fetchRemoteLocationListFail(state, action);
    case actionTypes.CREATE_REMOTE_LOCATION_START:
      return createRemoteLocationStart(state);
    case actionTypes.CREATE_REMOTE_LOCATION_SUCCESS:
      return createRemoteLocationSuccess(state, action);
    case actionTypes.CREATE_REMOTE_LOCATION_FAIL:
      return createRemoteLocationFail(state, action);
    case actionTypes.ASSIGN_LOCATION_REMOTE_LOCATION_START:
      return assignLocationRemoteLocationStart(state);
    case actionTypes.ASSIGN_LOCATION_REMOTE_LOCATION_SUCCESS:
      return assignLocationRemoteLocationSuccess(state);
    case actionTypes.ASSIGN_LOCATION_REMOTE_LOCATION_FAIL:
      return assignLocationRemoteLocationFail(state, action);
    case actionTypes.CHANGE_REMOTE_LOCATION_STATUS_REJECT_START:
      return remoteLocationChangeStatusRejectStart(state);
    case actionTypes.CHANGE_REMOTE_LOCATION_STATUS_REJECT_SUCCESS:
      return remoteLocationChangeStatusRejectSuccess(state);
    case actionTypes.CHANGE_REMOTE_LOCATION_STATUS_REJECT_FAIL:
      return remoteLocationChangeStatusRejectFail(state, action);
    case actionTypes.CHANGE_REMOTE_LOCATION_STATUS_ASSIGN_START:
      return remoteLocationChangeStatusAssignStart(state);
    case actionTypes.CHANGE_REMOTE_LOCATION_STATUS_ASSIGN_SUCCESS:
      return remoteLocationChangeStatusAssignSuccess(state);
    case actionTypes.CHANGE_REMOTE_LOCATION_STATUS_ASSIGN_FAIL:
      return remoteLocationChangeStatusAssignFail(state, action);
    case actionTypes.FETCH_LOCATION_REMOTE_LOCATIONS_START:
      return fetchLocationRemoteLocationsStart(state);
    case actionTypes.FETCH_LOCATION_REMOTE_LOCATIONS_SUCCESS:
      return fetchLocationRemoteLocationsSuccess(state, action);
    case actionTypes.FETCH_LOCATION_REMOTE_LOCATIONS_FAIL:
      return fetchLocationRemoteLocationsFail(state, action);
    case actionTypes.RESET_CREATED_REMOTE_LOCATION_STORE:
      return resetCreatedRemoteLocationStore(state);
    case actionTypes.RESET_REMOTE_LOCATION_STORE:
      return resetRemoteLocationStore();
    case actionTypes.LOGOUT:
      return completelyResetRemoteLocationStore();
    default:
      return state;
  }
};

export default reducer;
