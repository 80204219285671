import * as actionTypes from './actionTypes';
import { AmenityActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Amenity } from '../../domain/Amenity';

export type AmenityStateType = {
  amenitiesList: ListResults<Amenity> | null;
  amenitiesListLoading: boolean;
  amenitiesListError: HttpError;
  amenitiesListUpdateNeeded: boolean;
  amenityCreateLoading: boolean;
  amenityCreateError: HttpError;
  amenityCreateSuccess: boolean;
  createdAmenity: Amenity | null;
  amenityUpdateLoading: boolean;
  amenityUpdateError: HttpError;
  amenityUpdateSuccess: boolean;
  amenity: Amenity | null;
  amenityLoading: boolean;
  amenityError: HttpError;
  amenityDeleteLoading: boolean;
  amenityDeleteError: HttpError;
  amenityDeleteSuccess: boolean;
  allAmenities: Amenity[];
  allAmenitiesLoading: boolean;
  allAmenitiesError: HttpError;
};

export type AmenityActionType = AmenityStateType & {
  type: AmenityActionTypes;
};

export const initialState: AmenityStateType = {
  amenitiesList: null,
  amenitiesListLoading: true,
  amenitiesListError: null,
  amenitiesListUpdateNeeded: false,
  amenityCreateLoading: false,
  amenityCreateError: null,
  amenityCreateSuccess: false,
  createdAmenity: null,
  amenityUpdateLoading: false,
  amenityUpdateError: null,
  amenityUpdateSuccess: false,
  amenity: null,
  amenityLoading: false,
  amenityError: null,
  amenityDeleteLoading: false,
  amenityDeleteError: null,
  amenityDeleteSuccess: false,
  allAmenities: [],
  allAmenitiesLoading: false,
  allAmenitiesError: null,
};

const fetchAmenitiesListStart = (
  state: AmenityStateType,
): AmenityStateType => ({
  ...state,
  amenitiesListLoading: true,
});

const fetchAmenitiesListSuccess = (
  state: AmenityStateType,
  action: AmenityActionType,
): AmenityStateType => ({
  ...state,
  amenitiesListLoading: false,
  amenitiesList: action.amenitiesList,
  amenitiesListUpdateNeeded: false,
});

const fetchAmenitiesListFail = (
  state: AmenityStateType,
  action: AmenityActionType,
): AmenityStateType => ({
  ...state,
  amenitiesListLoading: false,
  amenitiesListError: action.amenitiesListError,
});

const fetchAmenityStart = (state: AmenityStateType): AmenityStateType => ({
  ...state,
  amenityLoading: true,
});

const fetchAmenitySuccess = (
  state: AmenityStateType,
  action: AmenityActionType,
): AmenityStateType => ({
  ...state,
  amenityLoading: false,
  amenity: action.amenity,
});

const fetchAmenityFail = (
  state: AmenityStateType,
  action: AmenityActionType,
): AmenityStateType => ({
  ...state,
  amenityLoading: false,
  amenityError: action.amenityError,
});

const createAmenityStart = (state: AmenityStateType): AmenityStateType => ({
  ...state,
  amenityCreateLoading: true,
});

const createAmenitySuccess = (
  state: AmenityStateType,
  action: AmenityActionType,
): AmenityStateType => ({
  ...state,
  amenityCreateLoading: false,
  amenityCreateError: null,
  amenityCreateSuccess: true,
  amenitiesListUpdateNeeded: true,
  createdAmenity: action.createdAmenity,
  amenitiesList: state.amenitiesList
    ? {
        total: state.amenitiesList.total + 1,
        result: [
          ...state.amenitiesList.result,
          action.createdAmenity,
        ] as Amenity[],
      }
    : null,
});

const createAmenityFail = (
  state: AmenityStateType,
  action: AmenityActionType,
): AmenityStateType => ({
  ...state,
  amenityCreateLoading: false,
  amenityCreateError: action.amenityCreateError,
});

const updateAmenityStart = (state: AmenityStateType): AmenityStateType => ({
  ...state,
  amenityUpdateLoading: true,
});

const updateAmenitySuccess = (state: AmenityStateType): AmenityStateType => ({
  ...state,
  amenityUpdateLoading: false,
  amenityUpdateSuccess: true,
  amenitiesListUpdateNeeded: true,
});

const updateAmenityFail = (
  state: AmenityStateType,
  action: AmenityActionType,
): AmenityStateType => ({
  ...state,
  amenityUpdateLoading: false,
  amenityUpdateError: action.amenityUpdateError,
});

const deleteAmenityStart = (state: AmenityStateType): AmenityStateType => ({
  ...state,
  amenityDeleteLoading: true,
});

const deleteAmenitySuccess = (state: AmenityStateType): AmenityStateType => ({
  ...state,
  amenityDeleteLoading: false,
  amenityDeleteSuccess: true,
  amenitiesListUpdateNeeded: true,
});

const deleteAmenityFail = (
  state: AmenityStateType,
  action: AmenityActionType,
): AmenityStateType => ({
  ...state,
  amenityDeleteLoading: false,
  amenityDeleteError: action.amenityDeleteError,
});

const fetchAllAmenitiesStart = (state: AmenityStateType): AmenityStateType => ({
  ...state,
  allAmenitiesLoading: true,
});

const fetchAllAmenitiesSuccess = (
  state: AmenityStateType,
  action: AmenityActionType,
): AmenityStateType => ({
  ...state,
  allAmenitiesLoading: false,
  allAmenities: action.allAmenities,
});

const fetchAllAmenitiesFail = (
  state: AmenityStateType,
  action: AmenityActionType,
): AmenityStateType => ({
  ...state,
  allAmenitiesError: action.allAmenitiesError,
  allAmenitiesLoading: false,
});

const resetCreatedAmenityStore = (
  state: AmenityStateType,
): AmenityStateType => ({
  ...state,
  amenityCreateLoading: false,
  amenityCreateError: null,
  amenityCreateSuccess: false,
  createdAmenity: null,
});

const resetAmenityStore = (): AmenityStateType => ({
  ...initialState,
});

const completelyResetAmenityStore = (): AmenityStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: AmenityActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_AMENITIES_LIST_START:
      return fetchAmenitiesListStart(state);
    case actionTypes.FETCH_AMENITIES_LIST_SUCCESS:
      return fetchAmenitiesListSuccess(state, action);
    case actionTypes.FETCH_AMENITIES_LIST_FAIL:
      return fetchAmenitiesListFail(state, action);
    case actionTypes.FETCH_AMENITY_START:
      return fetchAmenityStart(state);
    case actionTypes.FETCH_AMENITY_SUCCESS:
      return fetchAmenitySuccess(state, action);
    case actionTypes.FETCH_AMENITY_FAIL:
      return fetchAmenityFail(state, action);
    case actionTypes.FETCH_ALL_AMENITIES_START:
      return fetchAllAmenitiesStart(state);
    case actionTypes.FETCH_ALL_AMENITIES_SUCCESS:
      return fetchAllAmenitiesSuccess(state, action);
    case actionTypes.FETCH_ALL_AMENITIES_FAIL:
      return fetchAllAmenitiesFail(state, action);
    case actionTypes.CREATE_AMENITY_START:
      return createAmenityStart(state);
    case actionTypes.CREATE_AMENITY_SUCCESS:
      return createAmenitySuccess(state, action);
    case actionTypes.CREATE_AMENITY_FAIL:
      return createAmenityFail(state, action);
    case actionTypes.UPDATE_AMENITY_START:
      return updateAmenityStart(state);
    case actionTypes.UPDATE_AMENITY_SUCCESS:
      return updateAmenitySuccess(state);
    case actionTypes.UPDATE_AMENITY_FAIL:
      return updateAmenityFail(state, action);
    case actionTypes.DELETE_AMENITY_START:
      return deleteAmenityStart(state);
    case actionTypes.DELETE_AMENITY_SUCCESS:
      return deleteAmenitySuccess(state);
    case actionTypes.DELETE_AMENITY_FAIL:
      return deleteAmenityFail(state, action);
    case actionTypes.RESET_AMENITY_STORE:
      return resetAmenityStore();
    case actionTypes.RESET_CREATED_AMENITY_STORE:
      return resetCreatedAmenityStore(state);
    case actionTypes.LOGOUT:
      return completelyResetAmenityStore();
    default:
      return state;
  }
};

export default reducer;
