import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { PriceNotification } from '../../domain/PriceNotification';

export const fetchPriceNotificationsListStart = () => ({
  type: actionTypes.FETCH_PRICE_NOTIFICATIONS_LIST_START,
});

export const fetchPriceNotificationsListSuccess = (
  priceNotificationsList: ListResults<PriceNotification>,
) => ({
  type: actionTypes.FETCH_PRICE_NOTIFICATIONS_LIST_SUCCESS,
  priceNotificationsList,
});

export const fetchPriceNotificationsListFail = (
  priceNotificationsListError: HttpError,
) => ({
  type: actionTypes.FETCH_PRICE_NOTIFICATIONS_LIST_FAIL,
  priceNotificationsListError,
});

export const updatePriceNotificationIsSeenStatusStart = () => ({
  type: actionTypes.UPDATE_PRICE_NOTIFICATION_IS_SEEN_STATUS_START,
});

export const updatePriceNotificationIsSeenStatusSuccess = () => ({
  type: actionTypes.UPDATE_PRICE_NOTIFICATION_IS_SEEN_STATUS_SUCCESS,
});

export const updatePriceNotificationIsSeenStatusFail = (
  priceNotificationIsSeenStatusUpdateError: HttpError,
) => ({
  type: actionTypes.UPDATE_PRICE_NOTIFICATION_IS_SEEN_STATUS_FAIL,
  priceNotificationIsSeenStatusUpdateError,
});

export const resetPriceNotificationStore = () => ({
  type: actionTypes.RESET_PRICE_NOTIFICATION_STORE,
});
