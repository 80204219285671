import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../common/Loader/Loader';
import { StoreState } from '../StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../store/auth/service';
import * as userService from '../../store/user/service';
import { routes } from './routes';
import Layout from '../../common/Layout/Layout';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { JwtToken } from '../Axios/axios-instance';
import { logout, selectLocale } from '../../store/auth/actions';
import * as languageService from '../../store/language/service';
import { Language } from '../../domain/Language';
import { DEFAULT_LOCALE } from '../constants';
import { Locale } from '../../domain/Translation';
import { IntlProvider } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../common/ErrorFallback/ErrorFallback';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';
import { fetchAllPublicLanguages } from '../../store/language/service';
import { PublicLocation } from '../../domain/PublicLocation';
import * as locationService from '../../store/location/service';
import { updateSavedPublicLocations } from '../../store/location/actions';

const LoginPage = React.lazy(
  () => import('../../pages/Public/LoginPage/LoginPage'),
);

const UsersListPage = React.lazy(
  () => import('../../pages/Admin/User/UsersListPage/UsersListPage'),
);

const UserCreatePage = React.lazy(
  () => import('../../pages/Admin/User/UserCreatePage/UserCreatePage'),
);

const UserEditPage = React.lazy(
  () => import('../../pages/Admin/User/UserEditPage/UserEditPage'),
);

const TranslationsPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Translation/TranslationListPage/TranslationListPage'
    ),
);

const EnquiriesListPage = React.lazy(
  () => import('../../pages/Admin/Enquiry/EnquiriesListPage/EnquiriesListPage'),
);

const EmployeesListPage = React.lazy(
  () =>
    import('../../pages/Admin/Employee/EmployeesListPage/EmployeesListPage'),
);

const EmployeeEditPage = React.lazy(
  () => import('../../pages/Admin/Employee/EmployeeEditPage/EmployeeEditPage'),
);

const GuidesListPage = React.lazy(
  () => import('../../pages/Admin/Guide/GuidesListPage/GuidesListPage'),
);

const GuideCreatePage = React.lazy(
  () => import('../../pages/Admin/Guide/GuideCreatePage/GuideCreatePage'),
);

const GuideEditPage = React.lazy(
  () => import('../../pages/Admin/Guide/GuideEditPage/GuideEditPage'),
);

const PropertiesListPage = React.lazy(
  () =>
    import('../../pages/Admin/Property/PropertiesListPage/PropertiesListPage'),
);

const AmenitiesListPage = React.lazy(
  () => import('../../pages/Admin/Amenity/AmenitiesListPage/AmenitiesListPage'),
);

const CitiesListPage = React.lazy(
  () => import('../../pages/Admin/City/CitiesListPage/CitiesListPage'),
);

const TownAreasListPage = React.lazy(
  () =>
    import('../../pages/Admin/TownArea/TownAreasListPage/TownAreasListPage'),
);

const LocationsListPage = React.lazy(
  () => import('../../pages/Admin/Location/LocationListPage/LocationListPage'),
);

const LocationCreatePage = React.lazy(
  () =>
    import('../../pages/Admin/Location/LocationCreatePage/LocationCreatePage'),
);

const LocationEditPage = React.lazy(
  () => import('../../pages/Admin/Location/LocationEditPage/LocationEditPage'),
);

const DynamicPage = React.lazy(
  () => import('../../pages/Public/DynamicPage/DynamicPage'),
);

const IntegrationsListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Integration/IntegrationListPage/IntegrationListPage'
    ),
);

const CompanySettingsPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/CompanySettings/CompanySettingsEditPage/CompanySettingsEditPage'
    ),
);

const RemoteLocationsListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/RemoteLocation/RemoteLocationListPage/RemoteLocationListPage'
    ),
);

const LanguagesListPage = React.lazy(
  () => import('../../pages/Admin/Language/LanguagesListPage'),
);

const HomePage = React.lazy(
  () => import('../../pages/Public/HomePage/HomePage'),
);

const PublicPropertiesListPage = React.lazy(
  () => import('../../pages/Public/PropertiesListPage/PropertiesListPage'),
);

const PropertyPage = React.lazy(
  () => import('../../pages/Public/PropertyPage/PropertyPage'),
);

const ContactPage = React.lazy(
  () => import('../../pages/Public/ContactPage/ContactPage'),
);

const AboutUsPage = React.lazy(
  () => import('../../pages/Public/AboutUsPage/AboutUsPage'),
);

const ServicesPage = React.lazy(
  () => import('../../pages/Public/ServicesPage/ServicesPage'),
);

const GuidesPage = React.lazy(
  () => import('../../pages/Public/GuidesPage/GuidesPage'),
);

export type Props = {
  isInitCompleted: boolean;
  isAuthenticated: boolean;
  onTryAutoSignup: () => void;
  isCurrentUserLoading: boolean;
  refreshTokenLoading: boolean;
  onFetchCurrentUser: () => void;
  onRefreshToken: () => void;
  jwtToken: string | null;
  lastActionAt: moment.Moment | null;
  onLogout: () => void;
  onLanguageFetch: (locale: string) => void;
  language: Language | null;
  onLanguagesInit: () => void;
  onSelectLocale: (locale: Locale) => void;
  selectedLocale: Locale;
  languages: Language[] | null;
  currentUser: User | null;
  onFetchAllPublicLanguages: () => void;
  publicSavedLocations: PublicLocation[];
  publishedLocationsIds: number[] | null;
  onFetchPublishedLocationIds: () => void;
  onUpdateSavedPublicLocations: (location: PublicLocation[]) => void;
};

const ALLOWED_LOCALES = ['en', 'lt'];

export const Router = ({
  isInitCompleted,
  isAuthenticated,
  onTryAutoSignup,
  isCurrentUserLoading,
  onFetchCurrentUser,
  refreshTokenLoading,
  onRefreshToken,
  jwtToken,
  lastActionAt,
  onLogout,
  onLanguageFetch,
  language,
  onLanguagesInit,
  selectedLocale,
  onSelectLocale,
  languages,
  currentUser,
  onFetchAllPublicLanguages,
  publicSavedLocations,
  publishedLocationsIds,
  onFetchPublishedLocationIds,
  onUpdateSavedPublicLocations,
}: Props) => {
  const locale =
    window.location?.pathname?.substring(1)?.split('/')?.[0] ?? DEFAULT_LOCALE;
  const parsedLocale = (
    locale && ALLOWED_LOCALES.includes(locale) ? locale : DEFAULT_LOCALE
  ) as Locale;

  useEffect(() => {
    if (!jwtToken) {
      return;
    }

    const decodedJson: JwtToken = jwtDecode(jwtToken);

    if (!decodedJson) {
      return;
    }

    const difference = moment.duration(
      moment(decodedJson.exp * 1000).diff(moment()),
    );
    const differenceLastAction = moment.duration(moment().diff(lastActionAt));

    if (
      difference.asMinutes() < 5 &&
      differenceLastAction.asMinutes() < 5 &&
      !refreshTokenLoading
    ) {
      onRefreshToken();
    }

    const timeout = setTimeout(() => {
      onLogout();
    }, difference.asMilliseconds());

    return () => clearTimeout(timeout);
  }, [jwtToken, lastActionAt]);

  useEffect(() => {
    onTryAutoSignup();
    onFetchAllPublicLanguages();
    onFetchCurrentUser();
    onFetchPublishedLocationIds();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      onFetchCurrentUser();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!parsedLocale) {
      onLanguageFetch(parsedLocale ?? DEFAULT_LOCALE);
      return;
    }

    moment.locale(parsedLocale === 'en' ? 'en-gb' : parsedLocale);
    onLanguageFetch(parsedLocale);
    onLanguagesInit();
    onSelectLocale(parsedLocale);
  }, [parsedLocale]);

  useEffect(() => {
    if (!publishedLocationsIds) {
      return;
    }

    const removedPublicSavedLocations = publicSavedLocations.filter(
      (location) => !publishedLocationsIds.includes(location.id),
    );

    onUpdateSavedPublicLocations(removedPublicSavedLocations);
  }, [publishedLocationsIds, publicSavedLocations]);

  const mappedTranslations = language?.translations.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.alias]: item.value ? item.value : item.defaultValue,
      }),
    {},
  );

  const getRoutes = () => {
    if (!isAuthenticated) {
      return (
        <>
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.login} />}
          />
          <Route path={routes.homepage} element={<HomePage />} />
          <Route
            path={routes.propertiesListPage}
            element={<PublicPropertiesListPage />}
          />
          <Route path={routes.property} element={<PropertyPage />} />
          <Route path={routes.contact} element={<ContactPage />} />
          <Route path={routes.aboutUsPage} element={<AboutUsPage />} />
          <Route path={routes.servicesPage} element={<ServicesPage />} />
          <Route path={routes.guidesPage} element={<GuidesPage />} />
          <Route path={routes.login} element={<LoginPage />} />
          <Route path="*" element={<Navigate to={routes.login} />} />
        </>
      );
    }

    if (currentUser?.role === Roles.ADMIN) {
      return (
        <>
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.locations.list} />}
          />
          <Route path={routes.users.create} element={<UserCreatePage />} />
          <Route path={routes.users.edit} element={<UserEditPage />} />
          <Route path={routes.users.list} element={<UsersListPage />} />
          <Route path={routes.translations} element={<TranslationsPage />} />
          <Route path={routes.enquiries.list} element={<EnquiriesListPage />} />
          <Route path={routes.employees.list} element={<EmployeesListPage />} />
          <Route path={routes.guides.create} element={<GuideCreatePage />} />
          <Route path={routes.guides.edit} element={<GuideEditPage />} />
          <Route path={routes.guides.list} element={<GuidesListPage />} />
          <Route
            path={routes.properties.list}
            element={<PropertiesListPage />}
          />
          <Route path={routes.amenities.list} element={<AmenitiesListPage />} />
          <Route path={routes.cities.list} element={<CitiesListPage />} />
          <Route path={routes.townAreas.list} element={<TownAreasListPage />} />
          <Route path={routes.employees.edit} element={<EmployeeEditPage />} />
          <Route
            path={routes.locations.create}
            element={<LocationCreatePage />}
          />
          <Route path={routes.locations.edit} element={<LocationEditPage />} />
          <Route path={routes.locations.list} element={<LocationsListPage />} />
          <Route
            path={routes.integrations.list}
            element={<IntegrationsListPage />}
          />
          <Route
            path={routes.companySettings}
            element={<CompanySettingsPage />}
          />
          <Route
            path={routes.remoteLocations.list}
            element={<RemoteLocationsListPage />}
          />
          <Route path={routes.languages.list} element={<LanguagesListPage />} />
          <Route path={routes.homepage} element={<HomePage />} />
          <Route
            path={routes.propertiesListPage}
            element={<PublicPropertiesListPage />}
          />
          <Route path={routes.property} element={<PropertyPage />} />
          <Route path={routes.contact} element={<ContactPage />} />
          <Route path={routes.aboutUsPage} element={<AboutUsPage />} />
          <Route path={routes.servicesPage} element={<ServicesPage />} />
          <Route path={routes.guidesPage} element={<GuidesPage />} />
          <Route path={routes.dynamicPage} element={<DynamicPage />} />
          <Route path="*" element={<Navigate to={routes.admin} />} />
        </>
      );
    }

    if (currentUser?.role === Roles.EMPLOYEE) {
      return (
        <>
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.locations.list} />}
          />
          <Route path={routes.dynamicPage} element={<DynamicPage />} />
          <Route path="*" element={<Navigate to={routes.admin} />} />
        </>
      );
    }
  };

  return (
    <BrowserRouter basename="/">
      {isInitCompleted && !isCurrentUserLoading && language ? (
        <IntlProvider
          messages={mappedTranslations}
          locale={language?.locale ?? DEFAULT_LOCALE}
          defaultLocale="en"
        >
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Suspense fallback={<Loader isLoading isFullScreen />}>
              <Layout isAuthenticated={isAuthenticated}>
                <Routes>{getRoutes()}</Routes>
              </Layout>
            </Suspense>
          </ErrorBoundary>
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isInitCompleted: state.auth.isInitCompleted,
  isAuthenticated: state.auth.isAuthenticated,
  isCurrentUserLoading: state.user.currentUserLoading,
  refreshTokenLoading: state.auth.refreshTokenLoading,
  jwtToken: state.auth.jwtToken,
  lastActionAt: state.auth.lastStoreActionAt,
  language: state.language.language,
  selectedLocale: state.auth.selectedLocale,
  languages: state.language.languages,
  currentUser: state.user.currentUser,
  publicSavedLocations: state.location.publicSavedLocations,
  publishedLocationsIds: state.location.publishedLocationsIds,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onTryAutoSignup: () => dispatch(authService.authCheckState()),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser()),
  onRefreshToken: () => dispatch(authService.refreshToken()),
  onLanguageFetch: (locale: string) =>
    dispatch(languageService.fetchLanguage(locale)),
  onLanguagesInit: () => dispatch(languageService.fetchLanguages()),
  onLogout: () => dispatch(logout()),
  onSelectLocale: (locale: Locale) => dispatch(selectLocale(locale)),
  onFetchAllPublicLanguages: () => dispatch(fetchAllPublicLanguages()),
  onFetchPublishedLocationIds: () =>
    dispatch(locationService.fetchPublishedLocationsIds()),
  onUpdateSavedPublicLocations: (location: PublicLocation[]) =>
    dispatch(updateSavedPublicLocations(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
