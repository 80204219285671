export const FETCH_EMPLOYEES_LIST_START = 'FETCH_EMPLOYEES_LIST_START';
export const FETCH_EMPLOYEES_LIST_SUCCESS = 'FETCH_EMPLOYEES_LIST_SUCCESS';
export const FETCH_EMPLOYEES_LIST_FAIL = 'FETCH_EMPLOYEES_LIST_FAIL';

export const FETCH_ALL_EMPLOYEES_START = 'FETCH_ALL_EMPLOYEES_START';
export const FETCH_ALL_EMPLOYEES_SUCCESS = 'FETCH_ALL_EMPLOYEES_SUCCESS';
export const FETCH_ALL_EMPLOYEES_FAIL = 'FETCH_ALL_EMPLOYEES_FAIL';

export const FETCH_EMPLOYEE_START = 'FETCH_EMPLOYEE_START';
export const FETCH_EMPLOYEE_SUCCESS = 'FETCH_EMPLOYEE_SUCCESS';
export const FETCH_EMPLOYEE_FAIL = 'FETCH_EMPLOYEE_FAIL';

export const CREATE_EMPLOYEE_START = 'CREATE_EMPLOYEE_START';
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_FAIL = 'CREATE_EMPLOYEE_FAIL';

export const UPDATE_EMPLOYEE_START = 'UPDATE_EMPLOYEE_START';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_FAIL = 'UPDATE_EMPLOYEE_FAIL';

export const DELETE_EMPLOYEE_START = 'DELETE_EMPLOYEE_START';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_FAIL = 'DELETE_EMPLOYEE_FAIL';

export const RESET_EMPLOYEE_STORE = 'RESET_EMPLOYEE_STORE';
export const RESET_CREATED_EMPLOYEE = 'RESET_CREATED_EMPLOYEE';
export const RESET_UPDATED_EMPLOYEE = 'RESET_UPDATED_EMPLOYEE';
export const LOGOUT = 'LOGOUT';

export type EmployeeActionTypes =
  | typeof FETCH_EMPLOYEES_LIST_START
  | typeof FETCH_EMPLOYEES_LIST_SUCCESS
  | typeof FETCH_EMPLOYEES_LIST_FAIL
  | typeof FETCH_ALL_EMPLOYEES_START
  | typeof FETCH_ALL_EMPLOYEES_SUCCESS
  | typeof FETCH_ALL_EMPLOYEES_FAIL
  | typeof FETCH_EMPLOYEE_START
  | typeof FETCH_EMPLOYEE_SUCCESS
  | typeof FETCH_EMPLOYEE_FAIL
  | typeof CREATE_EMPLOYEE_START
  | typeof CREATE_EMPLOYEE_SUCCESS
  | typeof CREATE_EMPLOYEE_FAIL
  | typeof UPDATE_EMPLOYEE_START
  | typeof UPDATE_EMPLOYEE_SUCCESS
  | typeof UPDATE_EMPLOYEE_FAIL
  | typeof DELETE_EMPLOYEE_START
  | typeof DELETE_EMPLOYEE_SUCCESS
  | typeof DELETE_EMPLOYEE_FAIL
  | typeof RESET_EMPLOYEE_STORE
  | typeof RESET_CREATED_EMPLOYEE
  | typeof RESET_UPDATED_EMPLOYEE
  | typeof LOGOUT;
