export const FETCH_GUIDES_LIST_START = 'FETCH_GUIDES_LIST_START';
export const FETCH_GUIDES_LIST_SUCCESS = 'FETCH_GUIDES_LIST_SUCCESS';
export const FETCH_GUIDES_LIST_FAIL = 'FETCH_GUIDES_LIST_FAIL';

export const FETCH_GUIDE_START = 'FETCH_GUIDE_START';
export const FETCH_GUIDE_SUCCESS = 'FETCH_GUIDE_SUCCESS';
export const FETCH_GUIDE_FAIL = 'FETCH_GUIDE_FAIL';

export const FETCH_PUBLIC_GUIDES_START = 'FETCH_PUBLIC_GUIDES_START';
export const FETCH_PUBLIC_GUIDES_SUCCESS = 'FETCH_PUBLIC_GUIDES_SUCCESS';
export const FETCH_PUBLIC_GUIDES_FAIL = 'FETCH_PUBLIC_GUIDES_FAIL';

export const CREATE_GUIDE_START = 'CREATE_GUIDE_START';
export const CREATE_GUIDE_SUCCESS = 'CREATE_GUIDE_SUCCESS';
export const CREATE_GUIDE_FAIL = 'CREATE_GUIDE_FAIL';

export const UPDATE_GUIDE_START = 'UPDATE_GUIDE_START';
export const UPDATE_GUIDE_SUCCESS = 'UPDATE_GUIDE_SUCCESS';
export const UPDATE_GUIDE_FAIL = 'UPDATE_GUIDE_FAIL';

export const DELETE_GUIDE_START = 'DELETE_GUIDE_START';
export const DELETE_GUIDE_SUCCESS = 'DELETE_GUIDE_SUCCESS';
export const DELETE_GUIDE_FAIL = 'DELETE_GUIDE_FAIL';

export const RESET_CREATED_GUIDE_STORE = 'RESET_CREATED_GUIDE_STORE';
export const RESET_GUIDE_ERROR = 'RESET_GUIDE_ERROR';
export const RESET_GUIDE_STORE = 'RESET_GUIDE_STORE';

export const LOGOUT = 'LOGOUT';

export type GuideActionTypes =
  | typeof FETCH_GUIDES_LIST_START
  | typeof FETCH_GUIDES_LIST_SUCCESS
  | typeof FETCH_GUIDES_LIST_FAIL
  | typeof FETCH_GUIDE_START
  | typeof FETCH_GUIDE_SUCCESS
  | typeof FETCH_GUIDE_FAIL
  | typeof FETCH_PUBLIC_GUIDES_START
  | typeof FETCH_PUBLIC_GUIDES_SUCCESS
  | typeof FETCH_PUBLIC_GUIDES_FAIL
  | typeof CREATE_GUIDE_START
  | typeof CREATE_GUIDE_SUCCESS
  | typeof CREATE_GUIDE_FAIL
  | typeof UPDATE_GUIDE_START
  | typeof UPDATE_GUIDE_SUCCESS
  | typeof UPDATE_GUIDE_FAIL
  | typeof DELETE_GUIDE_START
  | typeof DELETE_GUIDE_SUCCESS
  | typeof DELETE_GUIDE_FAIL
  | typeof RESET_CREATED_GUIDE_STORE
  | typeof RESET_GUIDE_ERROR
  | typeof RESET_GUIDE_STORE
  | typeof LOGOUT;
