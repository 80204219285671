import * as actionTypes from './actionTypes';
import { ChatGptActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export type ChatGptStateType = {
  generatedLocationDescription: string | null;
  generatedLocationDescriptionLoading: boolean;
  generatedLocationDescriptionError: HttpError;
};

export type CityActionType = ChatGptStateType & {
  type: ChatGptActionTypes;
};

export const initialState: ChatGptStateType = {
  generatedLocationDescription: null,
  generatedLocationDescriptionLoading: false,
  generatedLocationDescriptionError: null,
};

const generateLocationDescriptionStart = (
  state: ChatGptStateType,
): ChatGptStateType => ({
  ...state,
  generatedLocationDescriptionLoading: true,
});

const generateLocationDescriptionSuccess = (
  state: ChatGptStateType,
  action: CityActionType,
): ChatGptStateType => ({
  ...state,
  generatedLocationDescriptionLoading: false,
  generatedLocationDescription: action.generatedLocationDescription,
});

const generateLocationDescriptionFail = (
  state: ChatGptStateType,
  action: CityActionType,
): ChatGptStateType => ({
  ...state,
  generatedLocationDescriptionLoading: false,
  generatedLocationDescriptionError: action.generatedLocationDescriptionError,
});

const resetGeneratedLocationDescription = (
  state: ChatGptStateType,
): ChatGptStateType => ({
  ...state,
  generatedLocationDescriptionLoading: false,
  generatedLocationDescription: null,
  generatedLocationDescriptionError: null,
});

const completelyResetChatGptStore = (): ChatGptStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CityActionType) => {
  switch (action.type) {
    case actionTypes.GENERATE_LOCATION_DESCRIPTION_START:
      return generateLocationDescriptionStart(state);
    case actionTypes.GENERATE_LOCATION_DESCRIPTION_SUCCESS:
      return generateLocationDescriptionSuccess(state, action);
    case actionTypes.GENERATE_LOCATION_DESCRIPTION_FAIL:
      return generateLocationDescriptionFail(state, action);
    case actionTypes.RESET_GENERATED_LOCATION_DESCRIPTION:
      return resetGeneratedLocationDescription(state);
    case actionTypes.LOGOUT:
      return completelyResetChatGptStore();
    default:
      return state;
  }
};

export default reducer;
