import * as actionTypes from './actionTypes';
import { DeepLActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Language } from '../../domain/Language';

export type TranslatedLocation = {
  language: Language;
  name: string;
  description: string;
  seoTitle: string;
  seoDescription: string;
};

export type DeepLStateType = {
  generatedTranslatedLocations: TranslatedLocation[];
  generatedTranslatedLocationsLoading: boolean;
  generatedTranslatedLocationsError: HttpError;
};

export type DeepLActionType = DeepLStateType & {
  type: DeepLActionTypes;
};

export const initialState: DeepLStateType = {
  generatedTranslatedLocations: [],
  generatedTranslatedLocationsLoading: false,
  generatedTranslatedLocationsError: null,
};

const generateLocationTranslationsStart = (
  state: DeepLStateType,
): DeepLStateType => ({
  ...state,
  generatedTranslatedLocationsLoading: true,
});

const generateLocationTranslationsSuccess = (
  state: DeepLStateType,
  action: DeepLActionType,
): DeepLStateType => ({
  ...state,
  generatedTranslatedLocationsLoading: false,
  generatedTranslatedLocations: action.generatedTranslatedLocations,
});

const generateLocationTranslationsFail = (
  state: DeepLStateType,
  action: DeepLActionType,
): DeepLStateType => ({
  ...state,
  generatedTranslatedLocationsLoading: false,
  generatedTranslatedLocationsError: action.generatedTranslatedLocationsError,
});

const resetGenerateLocationTranslations = (
  state: DeepLStateType,
): DeepLStateType => ({
  ...state,
  generatedTranslatedLocationsLoading: false,
  generatedTranslatedLocations: [],
  generatedTranslatedLocationsError: null,
});

const completelyResetDeepLStore = (): DeepLStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: DeepLActionType) => {
  switch (action.type) {
    case actionTypes.GENERATE_LOCATION_TRANSLATIONS_START:
      return generateLocationTranslationsStart(state);
    case actionTypes.GENERATE_LOCATION_TRANSLATIONS_SUCCESS:
      return generateLocationTranslationsSuccess(state, action);
    case actionTypes.GENERATE_LOCATION_TRANSLATIONS_FAIL:
      return generateLocationTranslationsFail(state, action);
    case actionTypes.RESET_GENERATED_LOCATION_TRANSLATIONS:
      return resetGenerateLocationTranslations(state);
    case actionTypes.LOGOUT:
      return completelyResetDeepLStore();
    default:
      return state;
  }
};

export default reducer;
