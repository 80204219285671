import React, { ReactNode } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styles from './FileInput.module.scss';
import cx from 'classnames';

type Props = {
  id: string;
  index: number;
  renderImage: (value: File | string, index: number) => ReactNode;
  file: string | File;
};

const SortableItem = ({ id, index, renderImage, file }: Props) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={cx(styles.sortableItem, { [styles.isDragging]: isDragging })}
    >
      {renderImage(file, index)}
    </div>
  );
};

export default SortableItem;
