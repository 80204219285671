import * as actionTypes from './actionTypes';
import { IntegrationActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Integration } from '../../domain/Integration';

export type IntegrationStateType = {
  integrationsList: ListResults<Integration> | null;
  integrationsListLoading: boolean;
  integrationsListError: HttpError;
  integrationsListUpdateNeeded: boolean;
  integrationToggleLoading: boolean;
  integrationToggleError: HttpError;
  integrationToggleSuccess: boolean;
  allIntegrationsLoading: boolean;
  allIntegrationsError: HttpError;
  allIntegrations: Integration[] | null;
};

export type IntegrationActionType = IntegrationStateType & {
  type: IntegrationActionTypes;
};

export const initialState: IntegrationStateType = {
  integrationsList: null,
  integrationsListLoading: true,
  integrationsListError: null,
  integrationsListUpdateNeeded: false,
  integrationToggleLoading: false,
  integrationToggleSuccess: false,
  integrationToggleError: null,
  allIntegrationsLoading: false,
  allIntegrationsError: null,
  allIntegrations: null,
};

const fetchIntegrationsListStart = (
  state: IntegrationStateType,
): IntegrationStateType => ({
  ...state,
  integrationsListLoading: false,
});

const fetchIntegrationsListSuccess = (
  state: IntegrationStateType,
  action: IntegrationActionType,
): IntegrationStateType => ({
  ...state,
  integrationsListLoading: false,
  integrationsList: action.integrationsList,
  integrationsListUpdateNeeded: false,
});

const fetchIntegrationsListFail = (
  state: IntegrationStateType,
  action: IntegrationActionType,
): IntegrationStateType => ({
  ...state,
  integrationsListLoading: false,
  integrationsListError: action.integrationsListError,
});

const toggleIntegrationStart = (
  state: IntegrationStateType,
): IntegrationStateType => ({
  ...state,
  integrationToggleLoading: true,
});

const toggleIntegrationSuccess = (
  state: IntegrationStateType,
): IntegrationStateType => ({
  ...state,
  integrationToggleLoading: false,
  integrationToggleSuccess: true,
  integrationsListUpdateNeeded: true,
});

const toggleIntegrationFail = (
  state: IntegrationStateType,
  action: IntegrationActionType,
): IntegrationStateType => ({
  ...state,
  integrationToggleLoading: false,
  integrationToggleError: action.integrationToggleError,
});

const fetchAllIntegrationsStart = (
  state: IntegrationStateType,
): IntegrationStateType => ({
  ...state,
  allIntegrationsLoading: true,
});

const fetchAllIntegrationsSuccess = (
  state: IntegrationStateType,
  action: IntegrationActionType,
): IntegrationStateType => ({
  ...state,
  allIntegrations: action.allIntegrations,
  allIntegrationsLoading: false,
});

const fetchAllIntegrationsFail = (
  state: IntegrationStateType,
  action: IntegrationActionType,
): IntegrationStateType => ({
  ...state,
  allIntegrationsError: action.allIntegrationsError,
  allIntegrationsLoading: false,
});

const resetIntegrationStore = (): IntegrationStateType => ({
  ...initialState,
});

const completelyResetObjectTypeStore = (): IntegrationStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: IntegrationActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_INTEGRATION_LIST_START:
      return fetchIntegrationsListStart(state);
    case actionTypes.FETCH_INTEGRATION_LIST_SUCCESS:
      return fetchIntegrationsListSuccess(state, action);
    case actionTypes.FETCH_INTEGRATION_LIST_FAIL:
      return fetchIntegrationsListFail(state, action);
    case actionTypes.TOGGLE_INTEGRATION_START:
      return toggleIntegrationStart(state);
    case actionTypes.TOGGLE_INTEGRATION_SUCCESS:
      return toggleIntegrationSuccess(state);
    case actionTypes.TOGGLE_INTEGRATION_FAIL:
      return toggleIntegrationFail(state, action);
    case actionTypes.FETCH_ALL_INTEGRATIONS_START:
      return fetchAllIntegrationsStart(state);
    case actionTypes.FETCH_ALL_INTEGRATIONS_SUCCESS:
      return fetchAllIntegrationsSuccess(state, action);
    case actionTypes.FETCH_ALL_INTEGRATIONS_FAIL:
      return fetchAllIntegrationsFail(state, action);
    case actionTypes.RESET_INTEGRATION_STORE:
      return resetIntegrationStore();
    case actionTypes.LOGOUT:
      return completelyResetObjectTypeStore();
    default:
      return state;
  }
};

export default reducer;
