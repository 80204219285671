import * as actionTypes from './actionTypes';
import { PriceNotificationActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { PriceNotification } from '../../domain/PriceNotification';

export type PriceNotificationStateType = {
  priceNotificationsList: ListResults<PriceNotification> | null;
  priceNotificationsListLoading: boolean;
  priceNotificationsListError: HttpError;
  priceNotificationsListUpdateNeeded: boolean;
  priceNotificationIsSeenStatusUpdateLoading: boolean;
  priceNotificationIsSeenStatusUpdateError: HttpError;
  priceNotificationIsSeenStatusUpdateSuccess: boolean;
};

export type PriceNotificationActionType = PriceNotificationStateType & {
  type: PriceNotificationActionTypes;
};

export const initialState: PriceNotificationStateType = {
  priceNotificationsList: null,
  priceNotificationsListLoading: true,
  priceNotificationsListError: null,
  priceNotificationsListUpdateNeeded: false,
  priceNotificationIsSeenStatusUpdateLoading: false,
  priceNotificationIsSeenStatusUpdateError: null,
  priceNotificationIsSeenStatusUpdateSuccess: false,
};

const fetchPriceNotificationsListStart = (
  state: PriceNotificationStateType,
): PriceNotificationStateType => ({
  ...state,
  priceNotificationsListLoading: true,
});

const fetchPriceNotificationsListSuccess = (
  state: PriceNotificationStateType,
  action: PriceNotificationActionType,
): PriceNotificationStateType => ({
  ...state,
  priceNotificationsListLoading: false,
  priceNotificationsList: action.priceNotificationsList,
  priceNotificationsListUpdateNeeded: false,
});

const fetchPriceNotificationsListFail = (
  state: PriceNotificationStateType,
  action: PriceNotificationActionType,
): PriceNotificationStateType => ({
  ...state,
  priceNotificationsListLoading: false,
  priceNotificationsListError: action.priceNotificationsListError,
});

const updatePriceNotificationIsSeenStatusStart = (
  state: PriceNotificationStateType,
): PriceNotificationStateType => ({
  ...state,
  priceNotificationIsSeenStatusUpdateLoading: true,
});

const updatePriceNotificationIsSeenStatusSuccess = (
  state: PriceNotificationStateType,
): PriceNotificationStateType => ({
  ...state,
  priceNotificationIsSeenStatusUpdateLoading: false,
  priceNotificationIsSeenStatusUpdateSuccess: true,
  priceNotificationsListUpdateNeeded: true,
});

const updatePriceNotificationIsSeenStatusFail = (
  state: PriceNotificationStateType,
  action: PriceNotificationActionType,
): PriceNotificationStateType => ({
  ...state,
  priceNotificationIsSeenStatusUpdateLoading: false,
  priceNotificationIsSeenStatusUpdateError:
    action.priceNotificationIsSeenStatusUpdateError,
});

const resetIntegrationStore = (): PriceNotificationStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: PriceNotificationActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PRICE_NOTIFICATIONS_LIST_START:
      return fetchPriceNotificationsListStart(state);
    case actionTypes.FETCH_PRICE_NOTIFICATIONS_LIST_SUCCESS:
      return fetchPriceNotificationsListSuccess(state, action);
    case actionTypes.FETCH_PRICE_NOTIFICATIONS_LIST_FAIL:
      return fetchPriceNotificationsListFail(state, action);
    case actionTypes.UPDATE_PRICE_NOTIFICATION_IS_SEEN_STATUS_START:
      return updatePriceNotificationIsSeenStatusStart(state);
    case actionTypes.UPDATE_PRICE_NOTIFICATION_IS_SEEN_STATUS_SUCCESS:
      return updatePriceNotificationIsSeenStatusSuccess(state);
    case actionTypes.UPDATE_PRICE_NOTIFICATION_IS_SEEN_STATUS_FAIL:
      return updatePriceNotificationIsSeenStatusFail(state, action);
    case actionTypes.RESET_PRICE_NOTIFICATION_STORE:
      return resetIntegrationStore();
    default:
      return state;
  }
};

export default reducer;
