import React, { useEffect, useRef, useState } from 'react';
import styles from './PublicNavigation.module.scss';
import { IconButton } from '@mui/material';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { Link, NavLink } from 'react-router-dom';
import { routes } from '../../config/Router/routes';
import logo from '../../assets/logo_white.svg';
import cx from 'classnames';
import { NavigationItem } from '../Layout/Layout';
import { getLocaleUrl } from '../../utility/url/urlHelper';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { Locale } from '../../domain/Translation';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../store/auth/service';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

export type Props = {
  onDrawerClick: () => void;
  isMobileMenuOpen: boolean;
  navigationItems: NavigationItem[];
  selectedLocale: Locale;
  isAuthenticated: boolean;
  onLogout: () => void;
};

const MOBILE_BREAK_POINT = 900;

export const PublicNavigation = ({
  onDrawerClick,
  isMobileMenuOpen,
  navigationItems,
  selectedLocale,
  isAuthenticated,
  onLogout,
}: Props) => {
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const { width } = useWindowSize();

  const containerRef = useRef<HTMLDivElement>(null);
  let oldScrollY = 0;

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        window.scrollY || containerRef.current?.scrollTop || 0;

      if (scrollPosition < oldScrollY) {
        oldScrollY = scrollPosition;
        setIsHidden(false);
        return;
      }

      oldScrollY = scrollPosition;
      setIsHidden(true);
      return;
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <header
        ref={containerRef}
        className={cx(styles.navigationContainer, {
          [styles.isHidden]: isHidden,
        })}
      >
        <div className={styles.innerNavigation}>
          {width && width >= MOBILE_BREAK_POINT && (
            <div className={styles.navigationItems}>
              {navigationItems.slice(0, 3).map((item) => (
                <Link key={item.label} to={item.to}>
                  {item.label}
                </Link>
              ))}
            </div>
          )}
          <div className={styles.logo}>
            <Link to={getLocaleUrl(routes.homepage, selectedLocale)}>
              <img src={logo} alt="" />
            </Link>
          </div>
          {width && width >= MOBILE_BREAK_POINT && (
            <div className={styles.navigationItems}>
              {navigationItems.slice(3, 6).map((item) => (
                <Link key={item.label} to={item.to}>
                  {item.label}
                </Link>
              ))}
            </div>
          )}
          <div className={styles.iconItems}>
            {width && width < MOBILE_BREAK_POINT && (
              <IconButton onClick={() => onDrawerClick()}>
                <FontAwesomeIcon
                  className={styles.drawerIcon}
                  icon={(isMobileMenuOpen ? faTimes : faBars) as IconProp}
                  fixedWidth
                  size="sm"
                />
              </IconButton>
            )}
          </div>
          {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
            <div className={styles.mobileMenu}>
              {navigationItems.map((item) => (
                <NavLink
                  key={item.label}
                  to={item.to}
                  className={({ isActive }) =>
                    cx(styles.navigationItem, {
                      [styles.activeSubItem]: isActive,
                    })
                  }
                >
                  {item.label}
                </NavLink>
              ))}
            </div>
          )}
        </div>
        <div className={styles.languageSwitcher}>
          <LanguageSwitcher />
        </div>
      </header>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogout: () => dispatch(authService.logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicNavigation);
