export const GENERATE_LOCATION_DESCRIPTION_START =
  'GENERATE_LOCATION_DESCRIPTION_START';
export const GENERATE_LOCATION_DESCRIPTION_SUCCESS =
  'GENERATE_LOCATION_DESCRIPTION_SUCCESS';
export const GENERATE_LOCATION_DESCRIPTION_FAIL =
  'GENERATE_LOCATION_DESCRIPTION_FAIL';

export const RESET_GENERATED_LOCATION_DESCRIPTION =
  'RESET_GENERATED_LOCATION_DESCRIPTION';

export const LOGOUT = 'LOGOUT';

export type ChatGptActionTypes =
  | typeof GENERATE_LOCATION_DESCRIPTION_START
  | typeof GENERATE_LOCATION_DESCRIPTION_SUCCESS
  | typeof GENERATE_LOCATION_DESCRIPTION_FAIL
  | typeof RESET_GENERATED_LOCATION_DESCRIPTION
  | typeof LOGOUT;
