import _, { trim } from 'lodash';
import prettyBytes from 'pretty-bytes';
import mime from 'mime';
import moment from 'moment';
import { IntlShape } from 'react-intl';
import { translate } from '../messageTranslator/translate';
import { EntityTranslation } from '../../domain/EntityTranslation';

const MESSAGE_REQUIRED = 'This field cannot be empty';
const MESSAGE_EMAIL = 'This field must be a valid email address';
const MESSAGE_TIME = 'Invalid time';
const MESSAGE_STRING_AND_NUMBER =
  'This field must contain only numbers and letters';
const MESSAGE_DATE_INVALID = 'This field must be a valid date';

export type ValidationRule = {
  type: string;
  value?: string | string[] | number;
  parameter?: any;
};

export const getValidationError = (
  value: string | string[] | number | File | File[] | EntityTranslation[],
  rules: Array<ValidationRule> | undefined,
  intl: IntlShape,
): Array<string> =>
  _.compact(
    _.map(rules, (rule) => {
      const validator = validatorFactory(rule);
      const validationValue =
        validator &&
        validator(
          // @ts-ignore
          value instanceof File ||
            (Array.isArray(value) &&
              (value as File[]).find((val) => val instanceof File))
            ? Array.isArray(value)
              ? (value as File[]).filter((val) => val instanceof File)
              : value
            : value.toString(),
          intl,
          rule.parameter,
        );
      if (validationValue) {
        return validationValue;
      }
    }),
  );

const validatorFactory = (rule: ValidationRule) => {
  switch (rule.type) {
    case 'required':
      return requiredValidator;
    case 'minLength':
      return minLengthValidator;
    case 'maxLength':
      return maxLengthValidator;
    case 'email':
      return emailValidator;
    case 'numbersAndStrings':
      return numbersAndStringsValidator;
    case 'min':
      return minValidator;
    case 'max':
      return maxValidator;
    case 'time':
      return timeValidator;
    case 'fileSize':
      return fileSizeValidator;
    case 'fileExtension':
      return fileExtensionValidator;
    case 'isValidDate':
      return isValidDate;
    case 'isPrice':
      return isPrice;
    case 'isUrl':
      return urlValidator;
    case 'phone':
      return phoneNumberValidator;
  }
};

const requiredValidator = (
  value: string | string[],
  intl: IntlShape,
): string | undefined =>
  value.length === 0
    ? translate(intl, 'VALIDATION.REQUIRED', MESSAGE_REQUIRED)
    : undefined;

const minLengthValidator = (
  value: string | string[],
  intl: IntlShape,
  length?: number,
): string | undefined =>
  value && length && value.length < length
    ? translate(
        intl,
        'VALIDATION.MIN_LENGTH',
        `This field must be more than ${length} symbols`,
      ).replace(':value', length.toString())
    : undefined;

const maxLengthValidator = (
  value: string | string[],
  intl: IntlShape,
  length?: number,
): string | undefined =>
  length && value.length > length
    ? translate(
        intl,
        'VALIDATION.MAX_LENGTH',
        `This field must be less than ${length} symbols`,
      ).replace(':value', length.toString())
    : undefined;

const numbersAndStringsValidator = (
  value: string | string[],
  intl: IntlShape,
): string | undefined =>
  /^[a-zA-Z0-9]*$/.test(trim(value.toString()))
    ? undefined
    : translate(
        intl,
        'VALIDATION.NUMBERS_AND_STRINGS',
        MESSAGE_STRING_AND_NUMBER,
      );

const emailValidator = (
  value: string | string[],
  intl: IntlShape,
): string | undefined =>
  value === '' || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trim(value.toString()))
    ? undefined
    : translate(intl, 'VALIDATION.EMAIL', MESSAGE_EMAIL);

const minValidator = (
  value: string | string[],
  intl: IntlShape,
  length?: number,
): string | undefined => {
  if (value === '') {
    return;
  }

  if (length === 0 && +value <= length) {
    return `${translate(
      intl,
      'VALIDATION.MIN_ZERO',
      'This field must be greater than 0',
    )}`;
  }

  return length && +value < length
    ? `${translate(intl, 'VALIDATION.MIN')} ${length}`
    : undefined;
};

const maxValidator = (
  value: string | string[],
  intl: IntlShape,
  length?: number,
): string | undefined =>
  length && +value > length
    ? `${translate(
        intl,
        'VALIDATION.MAX',
        'This field must be less than',
      )} ${length}`
    : undefined;

const timeValidator = (
  value: string | string[],
  intl: IntlShape,
): string | undefined =>
  /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(trim(value.toString()))
    ? undefined
    : translate(intl, 'VALIDATION.TIME', MESSAGE_TIME);

const fileSizeValidator = (
  value: string | string[],
  intl: IntlShape,
  size?: number,
): string | undefined => {
  const valuesToValidate = Array.isArray(value) ? value : [value];

  const errors = [];

  for (const val of valuesToValidate) {
    errors.push(fileSizeValidatorFn(val, intl, size));
  }

  return errors.filter((error) => error)?.[0];
};

const fileSizeValidatorFn = (
  value: string | string[],
  intl: IntlShape,
  size?: number,
): string | undefined => {
  const file = value as unknown as File;

  if (!(file instanceof File)) {
    return undefined;
  }

  return file && size && size > file.size
    ? undefined
    : `${translate(
        intl,
        'VALIDATION.MAX_FILE_SIZE',
        'File cannot be larger than',
      )} ${prettyBytes(size ?? 0)}`;
};

const fileExtensionValidator = (
  value: string | string[],
  intl: IntlShape,
  extensions?: string[],
): string | undefined => {
  const valuesToValidate = Array.isArray(value) ? value : [value];

  const errors = [];

  for (const val of valuesToValidate) {
    errors.push(fileExtensionValidatorFn(val, intl, extensions));
  }

  return errors.filter((error) => error)?.[0];
};

const fileExtensionValidatorFn = (
  value: string | string[],
  intl: IntlShape,
  extensions?: string[],
): string | undefined => {
  const file = value as unknown as File;

  if (!(file instanceof File)) {
    return undefined;
  }

  return value &&
    extensions &&
    extensions.includes(mime.getExtension(file.type) ?? '')
    ? undefined
    : `${translate(
        intl,
        'VALIDATION.FILE_TYPES',
        'File must be one of these types',
      )}: ${extensions?.toString().replace(',', ', ')}`;
};

const isValidDate = (value: string | string[]): string | undefined =>
  value === '' || moment(value).isValid() ? undefined : MESSAGE_DATE_INVALID;

const isPrice = (
  value: string | string[],
  intl: IntlShape,
): string | undefined =>
  /^(0|[1-9]\d*)(\.\d{1,2})?$|^$/.test(trim(value.toString()))
    ? undefined
    : translate(intl, 'VALIDATION.IS_VALID_PRICE');

const urlValidator = (
  value: string | string[],
  intl: IntlShape,
): string | undefined =>
  value === '' ||
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
    value.toString(),
  )
    ? undefined
    : translate(intl, 'VALIDATION.URL');

const phoneNumberValidator = (
  value: string | string[],
  intl: IntlShape,
): string | undefined =>
  value === '' ||
  /^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$/.test(
    trim(value.toString()),
  )
    ? undefined
    : translate(intl, 'VALIDATION.INVALID_PHONE');
