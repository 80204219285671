import React, { useEffect, useState } from 'react';
import styles from './LanguageSwitcher.module.scss';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { selectLocale } from '../../store/auth/actions';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { Popover } from '@mui/material';
import { Locale } from '../../domain/Translation';
import LanguageIcon from '@mui/icons-material/Language';
import { Language } from '../../domain/Language';

type Props = {
  onSelectLocale: (locale: Locale) => void;
  selectedLocale: Locale;
  publicLanguages: Language[];
};

const LanguageSwitcher = ({
  onSelectLocale,
  selectedLocale,
  publicLanguages,
}: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const languages = publicLanguages.map(({ locale }) => {
    return {
      label: locale.toUpperCase(),
      value: locale,
    };
  });

  useEffect(() => {
    const handleScroll = () => {
      setIsPopoverOpen(false);
      return;
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleClick = (locale: Locale) => {
    onSelectLocale(locale);
    setIsPopoverOpen(false);
  };

  const renderActions = () => {
    return languages.map((language, index) => (
      <button
        className={styles.popoverListItem}
        key={index.toString()}
        onClick={() => handleClick(language.value as Locale)}
      >
        {language?.label}
      </button>
    ));
  };

  return (
    <>
      <LanguageIcon
        className={styles.switcherButton}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setIsPopoverOpen(true);
        }}
      />
      <Popover
        className={styles.popover}
        open={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -15,
          horizontal: 35,
        }}
        disableScrollLock
      >
        <div className={styles.popoverContent}>
          <ul className={styles.popoverList}>{renderActions()}</ul>
        </div>
      </Popover>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
  publicLanguages: state.language.publicLanguages,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onSelectLocale: (locale: Locale) => dispatch(selectLocale(locale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
