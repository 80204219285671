import * as actionTypes from './actionTypes';
import { LocationActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Location } from '../../domain/Location';
import { PublicLocation } from '../../domain/PublicLocation';
import { storePublicFavoriteLocations } from '../../utility/localStorage/localStorageHelper';

const savedLocationsString = localStorage.getItem('publicSavedLocations');

export enum NavigationScrollId {
  // eslint-disable-next-line no-unused-vars
  CONTACT_FORM = 'contact-form',
  // eslint-disable-next-line no-unused-vars
  FAVORITE_LOCATIONS = 'favorite-locations',
}

export type LocationStateType = {
  locationsList: ListResults<Location> | null;
  locationsListLoading: boolean;
  locationsListError: HttpError;
  locationsListUpdateNeeded: boolean;
  locationCreateLoading: boolean;
  locationCreateError: HttpError;
  locationCreateSuccess: boolean;
  createdLocation: Location | null;
  locationUpdateLoading: boolean;
  locationUpdateError: HttpError;
  locationUpdateSuccess: boolean;
  location: Location | null;
  locationLoading: boolean;
  locationError: HttpError;
  locationUpdateNeeded: boolean;
  locationDeleteLoading: boolean;
  locationDeleteError: HttpError;
  locationDeleteSuccess: boolean;
  locationImagesUpdateLoading: boolean;
  locationImagesUpdateError: HttpError;
  locationImagesUpdateSuccess: boolean;
  locationBuildingPlansImagesUpdateLoading: boolean;
  locationBuildingPlansImagesUpdateError: HttpError;
  locationBuildingPlansImagesUpdateSuccess: boolean;
  locationPublishLoading: boolean;
  locationPublishError: HttpError;
  locationPublishSuccess: boolean;
  unpublishLocationLoading: boolean;
  unpublishLocationError: HttpError;
  unpublishLocationSuccess: boolean;
  allLocationsLoading: boolean;
  allLocationsError: HttpError;
  allLocations: Location[] | null;
  publicNewLocationsLoading: boolean;
  publicNewLocations: PublicLocation[];
  publicNewLocationsError: HttpError;
  publicPromotedLocationsLoading: boolean;
  publicPromotedLocations: PublicLocation[];
  publicPromotedLocationsError: HttpError;
  publicFeaturedLocationsLoading: boolean;
  publicFeaturedLocations: PublicLocation[];
  publicFeaturedLocationsError: HttpError;
  publicLocationsLoading: boolean;
  publicLocationsError: HttpError;
  publicLocations: ListResults<Location> | null;
  publicSimilarLocationsLoading: boolean;
  publicSimilarLocationsError: HttpError;
  publicSimilarLocations: Location[];
  publicLocationLoading: boolean;
  publicLocationError: HttpError;
  publicLocation: Location | null;
  similarLocationsLoading: boolean;
  similarLocationsError: HttpError;
  similarLocations: Location[];
  locationUpdateGalleryLinksLoading: boolean;
  locationUpdateGalleryLinksError: HttpError;
  locationUpdateGalleryLinksSuccess: boolean;
  publicSavedLocation: PublicLocation | null;
  publicSavedLocations: PublicLocation[];
  viewRecommendedLocationsLoading: boolean;
  viewRecommendedLocationsError: HttpError;
  viewRecommendedLocationsSuccess: boolean;
  publicContactScrollToId: NavigationScrollId | null;
  calculatePercentagesLoading: boolean;
  publishedLocationsIds: number[] | null;
  publishedLocationsIdsLoading: boolean;
  publishedLocationsIdsError: HttpError;
};

export type LocationActionType = LocationStateType & {
  type: LocationActionTypes;
};

export const initialState: LocationStateType = {
  locationsList: null,
  locationsListLoading: true,
  locationsListError: null,
  locationsListUpdateNeeded: false,
  locationCreateLoading: false,
  locationCreateError: null,
  locationCreateSuccess: false,
  createdLocation: null,
  locationUpdateLoading: false,
  locationUpdateError: null,
  locationUpdateSuccess: false,
  location: null,
  locationLoading: false,
  locationError: null,
  locationUpdateNeeded: false,
  locationDeleteLoading: false,
  locationDeleteError: null,
  locationDeleteSuccess: false,
  locationImagesUpdateLoading: false,
  locationImagesUpdateError: null,
  locationImagesUpdateSuccess: false,
  locationBuildingPlansImagesUpdateLoading: false,
  locationBuildingPlansImagesUpdateError: null,
  locationBuildingPlansImagesUpdateSuccess: false,
  locationPublishLoading: false,
  locationPublishError: null,
  locationPublishSuccess: false,
  unpublishLocationLoading: false,
  unpublishLocationError: null,
  unpublishLocationSuccess: false,
  allLocationsLoading: false,
  allLocationsError: null,
  allLocations: null,
  publicNewLocationsLoading: false,
  publicNewLocations: [],
  publicNewLocationsError: null,
  publicPromotedLocationsLoading: false,
  publicPromotedLocations: [],
  publicPromotedLocationsError: null,
  publicFeaturedLocationsLoading: false,
  publicFeaturedLocations: [],
  publicFeaturedLocationsError: null,
  publicLocationsLoading: true,
  publicLocationsError: null,
  publicLocations: null,
  publicSimilarLocationsLoading: false,
  publicSimilarLocationsError: null,
  publicSimilarLocations: [],
  publicLocationLoading: false,
  publicLocationError: null,
  publicLocation: null,
  similarLocationsLoading: false,
  similarLocationsError: null,
  similarLocations: [],
  locationUpdateGalleryLinksLoading: false,
  locationUpdateGalleryLinksError: null,
  locationUpdateGalleryLinksSuccess: false,
  publicSavedLocation: null,
  publicSavedLocations: savedLocationsString
    ? JSON.parse(savedLocationsString)
    : [],
  viewRecommendedLocationsLoading: false,
  viewRecommendedLocationsError: null,
  viewRecommendedLocationsSuccess: true,
  publicContactScrollToId: null,
  calculatePercentagesLoading: false,
  publishedLocationsIds: null,
  publishedLocationsIdsLoading: false,
  publishedLocationsIdsError: null,
};

const fetchLocationListStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationsListLoading: true,
});

const fetchLocationListSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationsListLoading: false,
  locationsList: action.locationsList,
  locationsListUpdateNeeded: false,
});

const fetchLocationListFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationsListLoading: false,
  locationsListError: action.locationsListError,
});

const fetchLocationStart = (state: LocationStateType): LocationStateType => ({
  ...state,
  locationLoading: true,
  location: null,
});

const fetchLocationSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationLoading: false,
  locationUpdateNeeded: false,
  location: action.location,
});

const fetchLocationFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationLoading: false,
  locationUpdateNeeded: false,
  locationError: action.locationError,
});

const calculatePercentagesStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  calculatePercentagesLoading: true,
});

const calculatePercentagesSuccess = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  calculatePercentagesLoading: false,
});

const calculatePercentagesFail = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  calculatePercentagesLoading: false,
});

const createLocationStart = (state: LocationStateType): LocationStateType => ({
  ...state,
  locationCreateLoading: true,
});

const createLocationSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationCreateLoading: false,
  locationCreateError: null,
  locationCreateSuccess: true,
  locationsListUpdateNeeded: true,
  createdLocation: action.createdLocation,
  locationsList: state.locationsList
    ? {
        total: state.locationsList.total + 1,
        result: [
          ...state.locationsList.result,
          action.createdLocation,
        ] as Location[],
      }
    : null,
});

const createLocationFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationCreateLoading: false,
  locationCreateError: action.locationCreateError,
});

const updateLocationStart = (state: LocationStateType): LocationStateType => ({
  ...state,
  locationUpdateLoading: true,
});

const updateLocationSuccess = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationUpdateLoading: false,
  locationUpdateSuccess: true,
  locationsListUpdateNeeded: true,
});

const updateLocationFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationUpdateLoading: false,
  locationUpdateError: action.locationUpdateError,
});

const deleteLocationStart = (state: LocationStateType): LocationStateType => ({
  ...state,
  locationDeleteLoading: true,
});

const deleteLocationSuccess = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationDeleteLoading: false,
  locationDeleteSuccess: true,
  locationsListUpdateNeeded: true,
});

const deleteLocationFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationDeleteLoading: false,
  locationDeleteError: action.locationDeleteError,
});

const resetCreatedLocationStore = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationCreateLoading: false,
  locationCreateError: null,
  locationCreateSuccess: false,
  createdLocation: null,
});

const updateLocationImagesStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationImagesUpdateLoading: true,
});

const updateLocationImagesSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationImagesUpdateLoading: false,
  locationImagesUpdateSuccess: true,
  locationImagesUpdateError: null,
  location: action.location,
  locationUpdateNeeded: true,
});

const updateLocationImagesFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationImagesUpdateLoading: false,
  locationImagesUpdateError: action.locationImagesUpdateError,
});

const resetUploadLocationImage = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationImagesUpdateLoading: false,
  locationImagesUpdateSuccess: false,
});

const updateLocationBuildingPlansImagesStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationBuildingPlansImagesUpdateLoading: true,
});

const updateLocationBuildingPlansImagesSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationBuildingPlansImagesUpdateLoading: false,
  locationBuildingPlansImagesUpdateSuccess: true,
  location: action.location,
  locationUpdateNeeded: true,
});

const updateLocationBuildingPlansImagesFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationBuildingPlansImagesUpdateLoading: false,
  locationBuildingPlansImagesUpdateError:
    action.locationBuildingPlansImagesUpdateError,
});

const resetUpdateLocationBuildingPlansImages = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationBuildingPlansImagesUpdateLoading: false,
  locationBuildingPlansImagesUpdateSuccess: false,
});

const locationPublishStart = (state: LocationStateType): LocationStateType => ({
  ...state,
  locationPublishLoading: true,
});

const locationPublishSuccess = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationPublishLoading: false,
  locationPublishSuccess: true,
});

const locationPublishFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationPublishLoading: false,
  locationPublishError: action.locationPublishError,
});

const unpublishLocationStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  unpublishLocationLoading: true,
});

const unpublishLocationSuccess = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  unpublishLocationLoading: false,
  unpublishLocationSuccess: true,
});

const unpublishLocationFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  unpublishLocationLoading: false,
  unpublishLocationError: action.unpublishLocationError,
});

const fetchAllLocationsStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  allLocationsLoading: true,
});

const fetchAllLocationsSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  allLocations: action.allLocations,
  allLocationsLoading: false,
});

const fetchAllLocationsFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  allLocationsError: action.allLocationsError,
  allLocationsLoading: false,
});

const fetchPublicNewLocationsStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  publicNewLocationsLoading: true,
});

const fetchPublicNewLocationsSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  publicNewLocationsLoading: false,
  publicNewLocations: action.publicNewLocations,
});

const fetchPublicNewLocationsFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  publicNewLocationsLoading: false,
  publicNewLocationsError: action.publicNewLocationsError,
});

const fetchPublicPromotedLocationsStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  publicPromotedLocationsLoading: true,
});

const fetchPublicPromotedLocationsSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  publicPromotedLocationsLoading: false,
  publicPromotedLocations: action.publicPromotedLocations,
});

const fetchPublicPromotedLocationsFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  publicPromotedLocationsLoading: false,
  publicPromotedLocationsError: action.publicPromotedLocationsError,
});

const fetchPublicFeaturedLocationsStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  publicFeaturedLocationsLoading: true,
});

const fetchPublicFeaturedLocationsSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  publicFeaturedLocationsLoading: false,
  publicFeaturedLocations: action.publicFeaturedLocations,
});

const fetchPublicFeaturedLocationsFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  publicFeaturedLocationsLoading: false,
  publicFeaturedLocationsError: action.publicFeaturedLocationsError,
});

const fetchPublicLocationsStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  publicLocationsLoading: true,
});

const fetchPublicLocationsSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  publicLocations: action.publicLocations,
  publicLocationsLoading: false,
});

const fetchPublicLocationsFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  publicLocationsError: action.publicLocationsError,
  publicLocationsLoading: false,
});

const fetchPublicLocationStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  publicLocationLoading: true,
});

const fetchPublicLocationSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  publicLocation: action.publicLocation,
  publicLocationLoading: false,
});

const fetchPublicLocationFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  publicLocationError: action.publicLocationError,
  publicLocationLoading: false,
});

const fetchSimilarLocationsStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  similarLocationsLoading: true,
});

const fetchSimilarLocationsSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  similarLocations: action.similarLocations,
  similarLocationsLoading: false,
});

const fetchSimilarLocationsFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  similarLocationsError: action.similarLocationsError,
  similarLocationsLoading: false,
});

const fetchPublicSimilarLocationsStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  publicSimilarLocationsLoading: true,
});

const fetchPublicSimilarLocationsSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  publicSimilarLocations: action.publicSimilarLocations,
  publicSimilarLocationsLoading: false,
});

const fetchPublicSimilarLocationsFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  publicSimilarLocationsError: action.publicSimilarLocationsError,
  publicSimilarLocationsLoading: false,
});

const fetchPublishedLocationsIdsStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  publishedLocationsIdsLoading: true,
});

const fetchPublishedLocationsIdsSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  publishedLocationsIds: action.publishedLocationsIds,
  publishedLocationsIdsLoading: false,
});

const fetchPublishedLocationsIdsFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  publishedLocationsIdsError: action.publishedLocationsIdsError,
  publishedLocationsIdsLoading: false,
});

const updateLocationGalleryLinksStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationUpdateGalleryLinksLoading: true,
});

const updateLocationGalleryLinksSuccess = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationUpdateGalleryLinksLoading: false,
  locationUpdateGalleryLinksSuccess: true,
  locationUpdateNeeded: true,
});

const updateLocationGalleryLinksFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationUpdateGalleryLinksLoading: false,
  locationUpdateGalleryLinksError: action.locationUpdateGalleryLinksError,
});

const resetLocationGalleryLinksUpdateStore = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationUpdateGalleryLinksLoading: false,
  locationUpdateGalleryLinksSuccess: false,
  locationUpdateGalleryLinksError: null,
});

const resetLocationStore = (): LocationStateType => ({
  ...initialState,
});

const resetLocationError = (state: LocationStateType): LocationStateType => ({
  ...state,
  locationCreateError: null,
  locationUpdateError: null,
});

const resetUpdatedLocationStore = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationUpdateLoading: false,
  locationUpdateError: null,
  locationUpdateSuccess: false,
  location: null,
});

const updatePublicSavedLocations = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => {
  const locationsToUpdate = (
    Array.isArray(action.publicSavedLocation)
      ? action.publicSavedLocation
      : [action.publicSavedLocation]
  ).filter((publicSavedLocation) => publicSavedLocation.id);

  if (!locationsToUpdate.length) {
    return state;
  }

  const locationsToUpdateIds = locationsToUpdate.map((location) => location.id);

  const existingLocations = state.publicSavedLocations.some((publicLocation) =>
    locationsToUpdateIds.includes(publicLocation.id),
  );

  if (existingLocations) {
    const filteredSavedLocations = state.publicSavedLocations.filter(
      (publicLocation) => !locationsToUpdateIds.includes(publicLocation.id),
    );

    storePublicFavoriteLocations(filteredSavedLocations);

    return {
      ...state,
      publicSavedLocations: filteredSavedLocations,
      ...(!Array.isArray(action.publicSavedLocation)
        ? { publicSavedLocation: action.publicSavedLocation }
        : {}),
    };
  }

  const newSavedLocations = [
    ...state.publicSavedLocations,
    ...locationsToUpdate,
  ];

  storePublicFavoriteLocations(newSavedLocations);

  return {
    ...state,
    publicSavedLocations: newSavedLocations,
    ...(!Array.isArray(action.publicSavedLocation)
      ? { publicSavedLocation: action.publicSavedLocation }
      : {}),
  };
};

const viewRecommendedLocationsStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  viewRecommendedLocationsLoading: true,
});

const viewRecommendedLocationsSuccess = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  viewRecommendedLocationsLoading: false,
  viewRecommendedLocationsSuccess: true,
  viewRecommendedLocationsError: null,
});

const viewRecommendedLocationsFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  viewRecommendedLocationsLoading: false,
  viewRecommendedLocationsError: action.viewRecommendedLocationsError,
});

const resetPublicScrollToId = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  publicContactScrollToId: null,
});

const setPublicScrollToId = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  publicContactScrollToId: action.publicContactScrollToId,
});

const completelyResetLocationStore = (): LocationStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: LocationActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_LOCATIONS_LIST_START:
      return fetchLocationListStart(state);
    case actionTypes.FETCH_LOCATIONS_LIST_SUCCESS:
      return fetchLocationListSuccess(state, action);
    case actionTypes.FETCH_LOCATIONS_LIST_FAIL:
      return fetchLocationListFail(state, action);
    case actionTypes.FETCH_LOCATION_START:
      return fetchLocationStart(state);
    case actionTypes.FETCH_LOCATION_SUCCESS:
      return fetchLocationSuccess(state, action);
    case actionTypes.FETCH_LOCATION_FAIL:
      return fetchLocationFail(state, action);
    case actionTypes.CALCULATE_PERCENTAGES_START:
      return calculatePercentagesStart(state);
    case actionTypes.CALCULATE_PERCENTAGES_SUCCESS:
      return calculatePercentagesSuccess(state);
    case actionTypes.CALCULATE_PERCENTAGES_FAIL:
      return calculatePercentagesFail(state);
    case actionTypes.CREATE_LOCATION_START:
      return createLocationStart(state);
    case actionTypes.CREATE_LOCATION_SUCCESS:
      return createLocationSuccess(state, action);
    case actionTypes.CREATE_LOCATION_FAIL:
      return createLocationFail(state, action);
    case actionTypes.UPDATE_LOCATION_START:
      return updateLocationStart(state);
    case actionTypes.UPDATE_LOCATION_SUCCESS:
      return updateLocationSuccess(state);
    case actionTypes.UPDATE_LOCATION_FAIL:
      return updateLocationFail(state, action);
    case actionTypes.DELETE_LOCATION_START:
      return deleteLocationStart(state);
    case actionTypes.DELETE_LOCATION_SUCCESS:
      return deleteLocationSuccess(state);
    case actionTypes.DELETE_LOCATION_FAIL:
      return deleteLocationFail(state, action);
    case actionTypes.UPDATE_LOCATION_IMAGES_START:
      return updateLocationImagesStart(state);
    case actionTypes.UPDATE_LOCATION_IMAGES_SUCCESS:
      return updateLocationImagesSuccess(state, action);
    case actionTypes.UPDATE_LOCATION_IMAGES_FAIL:
      return updateLocationImagesFail(state, action);
    case actionTypes.RESET_UPLOAD_LOCATION_IMAGE:
      return resetUploadLocationImage(state);
    case actionTypes.UPDATE_LOCATION_BUILDING_PLANS_IMAGES_START:
      return updateLocationBuildingPlansImagesStart(state);
    case actionTypes.UPDATE_LOCATION_BUILDING_PLANS_IMAGES_SUCCESS:
      return updateLocationBuildingPlansImagesSuccess(state, action);
    case actionTypes.UPDATE_LOCATION_BUILDING_PLANS_IMAGES_FAIL:
      return updateLocationBuildingPlansImagesFail(state, action);
    case actionTypes.RESET_UPDATE_LOCATION_BUILDING_PLANS_IMAGES:
      return resetUpdateLocationBuildingPlansImages(state);
    case actionTypes.PUBLISH_LOCATION_START:
      return locationPublishStart(state);
    case actionTypes.PUBLISH_LOCATION_SUCCESS:
      return locationPublishSuccess(state);
    case actionTypes.PUBLISH_LOCATION_FAIL:
      return locationPublishFail(state, action);
    case actionTypes.UNPUBLISH_LOCATION_START:
      return unpublishLocationStart(state);
    case actionTypes.UNPUBLISH_LOCATION_SUCCESS:
      return unpublishLocationSuccess(state);
    case actionTypes.UNPUBLISH_LOCATION_FAIL:
      return unpublishLocationFail(state, action);
    case actionTypes.FETCH_ALL_LOCATIONS_START:
      return fetchAllLocationsStart(state);
    case actionTypes.FETCH_ALL_LOCATIONS_SUCCESS:
      return fetchAllLocationsSuccess(state, action);
    case actionTypes.FETCH_ALL_LOCATIONS_FAIL:
      return fetchAllLocationsFail(state, action);
    case actionTypes.FETCH_PUBLIC_NEW_LOCATIONS_START:
      return fetchPublicNewLocationsStart(state);
    case actionTypes.FETCH_PUBLIC_NEW_LOCATIONS_SUCCESS:
      return fetchPublicNewLocationsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_NEW_LOCATIONS_FAIL:
      return fetchPublicNewLocationsFail(state, action);
    case actionTypes.FETCH_PUBLIC_PROMOTED_LOCATIONS_START:
      return fetchPublicPromotedLocationsStart(state);
    case actionTypes.FETCH_PUBLIC_PROMOTED_LOCATIONS_SUCCESS:
      return fetchPublicPromotedLocationsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_PROMOTED_LOCATIONS_FAIL:
      return fetchPublicPromotedLocationsFail(state, action);
    case actionTypes.FETCH_PUBLIC_FEATURED_LOCATIONS_START:
      return fetchPublicFeaturedLocationsStart(state);
    case actionTypes.FETCH_PUBLIC_FEATURED_LOCATIONS_SUCCESS:
      return fetchPublicFeaturedLocationsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_FEATURED_LOCATIONS_FAIL:
      return fetchPublicFeaturedLocationsFail(state, action);
    case actionTypes.FETCH_PUBLIC_LOCATIONS_START:
      return fetchPublicLocationsStart(state);
    case actionTypes.FETCH_PUBLIC_LOCATIONS_SUCCESS:
      return fetchPublicLocationsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_LOCATIONS_FAIL:
      return fetchPublicLocationsFail(state, action);
    case actionTypes.FETCH_PUBLIC_SIMILAR_LOCATIONS_START:
      return fetchPublicSimilarLocationsStart(state);
    case actionTypes.FETCH_PUBLIC_SIMILAR_LOCATIONS_SUCCESS:
      return fetchPublicSimilarLocationsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_SIMILAR_LOCATIONS_FAIL:
      return fetchPublicSimilarLocationsFail(state, action);
    case actionTypes.FETCH_PUBLISHED_LOCATIONS_IDS_START:
      return fetchPublishedLocationsIdsStart(state);
    case actionTypes.FETCH_PUBLISHED_LOCATIONS_IDS_SUCCESS:
      return fetchPublishedLocationsIdsSuccess(state, action);
    case actionTypes.FETCH_PUBLISHED_LOCATIONS_IDS_FAIL:
      return fetchPublishedLocationsIdsFail(state, action);
    case actionTypes.FETCH_PUBLIC_LOCATION_START:
      return fetchPublicLocationStart(state);
    case actionTypes.FETCH_PUBLIC_LOCATION_SUCCESS:
      return fetchPublicLocationSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_LOCATION_FAIL:
      return fetchPublicLocationFail(state, action);
    case actionTypes.FETCH_SIMILAR_LOCATIONS_START:
      return fetchSimilarLocationsStart(state);
    case actionTypes.FETCH_SIMILAR_LOCATIONS_SUCCESS:
      return fetchSimilarLocationsSuccess(state, action);
    case actionTypes.FETCH_SIMILAR_LOCATIONS_FAIL:
      return fetchSimilarLocationsFail(state, action);
    case actionTypes.UPDATE_LOCATION_GALLERY_LINKS_START:
      return updateLocationGalleryLinksStart(state);
    case actionTypes.UPDATE_LOCATION_GALLERY_LINKS_SUCCESS:
      return updateLocationGalleryLinksSuccess(state);
    case actionTypes.UPDATE_LOCATION_GALLERY_LINKS_FAIL:
      return updateLocationGalleryLinksFail(state, action);
    case actionTypes.UPDATE_SAVED_PUBLIC_LOCATIONS:
      return updatePublicSavedLocations(state, action);
    case actionTypes.VIEW_RECOMMENDED_LOCATIONS_START:
      return viewRecommendedLocationsStart(state);
    case actionTypes.VIEW_RECOMMENDED_LOCATIONS_SUCCESS:
      return viewRecommendedLocationsSuccess(state);
    case actionTypes.VIEW_RECOMMENDED_LOCATIONS_FAIL:
      return viewRecommendedLocationsFail(state, action);
    case actionTypes.SET_PUBLIC_SCROLL_TO_ID:
      return setPublicScrollToId(state, action);
    case actionTypes.RESET_LOCATION_GALLERY_LINKS_UPDATE_STORE:
      return resetLocationGalleryLinksUpdateStore(state);
    case actionTypes.RESET_LOCATION_STORE:
      return resetLocationStore();
    case actionTypes.RESET_CREATED_LOCATION_STORE:
      return resetCreatedLocationStore(state);
    case actionTypes.RESET_LOCATION_ERROR:
      return resetLocationError(state);
    case actionTypes.RESET_UPDATED_LOCATION_STORE:
      return resetUpdatedLocationStore(state);
    case actionTypes.RESET_PUBLIC_SCROLL_TO_ID:
      return resetPublicScrollToId(state);
    case actionTypes.LOGOUT:
      return completelyResetLocationStore();
    default:
      return state;
  }
};

export default reducer;
