import _ from 'lodash';

export const getFormData = (inputs: { [key: string]: any }): FormData => {
  const form = new FormData();
  getKeyData(form, inputs);

  return form;
};

export const getKeyData = (
  form: FormData,
  inputs: { [key: string]: any },
  prefix: string = '',
) => {
  Object.keys(inputs).forEach((inputKey: string) => {
    if (inputs[inputKey] instanceof File || inputs[inputKey] instanceof Blob) {
      form.append(`${prefix ? `${prefix}` : inputKey}`, inputs[inputKey]);
      return;
    }

    if (_.isArray(inputs[inputKey]) || _.isObject(inputs[inputKey])) {
      getKeyData(
        form,
        inputs[inputKey],
        `${prefix ? `${prefix}[${inputKey}]` : inputKey}`,
      );
      return;
    }

    form.append(
      `${prefix ? `${prefix}[${inputKey}]` : inputKey}`,
      inputs[inputKey],
    );
  });
};
