import { LOCAL_STORAGE_PUBLIC_SAVED_LOCATIONS } from '../../config/constants';
import { PublicLocation } from '../../domain/PublicLocation';

export const storePublicFavoriteLocations = (
  publicLocations: PublicLocation[],
) => {
  const newSavedLocationsJSON = JSON.stringify(publicLocations);

  localStorage.setItem(
    LOCAL_STORAGE_PUBLIC_SAVED_LOCATIONS,
    newSavedLocationsJSON,
  );
};
