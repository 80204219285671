export const FETCH_REMOTE_LOCATIONS_LIST_START =
  'FETCH_REMOTE_LOCATIONS_LIST_START';
export const FETCH_REMOTE_LOCATIONS_LIST_SUCCESS =
  'FETCH_REMOTE_LOCATIONS_LIST_SUCCESS';
export const FETCH_REMOTE_LOCATIONS_LIST_FAIL =
  'FETCH_REMOTE_LOCATIONS_LIST_FAIL';

export const CREATE_REMOTE_LOCATION_START = 'CREATE_REMOTE_LOCATION_START';
export const CREATE_REMOTE_LOCATION_SUCCESS = 'CREATE_REMOTE_LOCATION_SUCCESS';
export const CREATE_REMOTE_LOCATION_FAIL = 'CREATE_REMOTE_LOCATION_FAIL';

export const ASSIGN_LOCATION_REMOTE_LOCATION_START =
  'ASSIGN_LOCATION_REMOTE_LOCATION_START';
export const ASSIGN_LOCATION_REMOTE_LOCATION_SUCCESS =
  'ASSIGN_LOCATION_REMOTE_LOCATION_SUCCESS';
export const ASSIGN_LOCATION_REMOTE_LOCATION_FAIL =
  'ASSIGN_LOCATION_REMOTE_LOCATION_FAIL';

export const CHANGE_REMOTE_LOCATION_STATUS_ASSIGN_START =
  'CHANGE_REMOTE_LOCATION_STATUS_ASSIGN_START';
export const CHANGE_REMOTE_LOCATION_STATUS_ASSIGN_SUCCESS =
  'CHANGE_REMOTE_LOCATION_STATUS_ASSIGN_SUCCESS';
export const CHANGE_REMOTE_LOCATION_STATUS_ASSIGN_FAIL =
  'CHANGE_REMOTE_LOCATION_STATUS_ASSIGN_FAIL';

export const CHANGE_REMOTE_LOCATION_STATUS_REJECT_START =
  'CHANGE_REMOTE_LOCATION_STATUS_REJECT_START';
export const CHANGE_REMOTE_LOCATION_STATUS_REJECT_SUCCESS =
  'CHANGE_REMOTE_LOCATION_STATUS_REJECT_SUCCESS';
export const CHANGE_REMOTE_LOCATION_STATUS_REJECT_FAIL =
  'CHANGE_REMOTE_LOCATION_STATUS_REJECT_FAIL';

export const FETCH_LOCATION_REMOTE_LOCATIONS_START =
  'FETCH_LOCATION_REMOTE_LOCATIONS_START';
export const FETCH_LOCATION_REMOTE_LOCATIONS_SUCCESS =
  'FETCH_LOCATION_REMOTE_LOCATIONS_SUCCESS';
export const FETCH_LOCATION_REMOTE_LOCATIONS_FAIL =
  'FETCH_LOCATION_REMOTE_LOCATIONS_FAIL';

export const RESET_REMOTE_LOCATION_STORE = 'RESET_REMOTE_LOCATION_STORE';
export const RESET_CREATED_REMOTE_LOCATION_STORE =
  'RESET_CREATED_REMOTE_LOCATION_STORE';

export const LOGOUT = 'LOGOUT';

export type RemoteLocationActionTypes =
  | typeof FETCH_REMOTE_LOCATIONS_LIST_START
  | typeof FETCH_REMOTE_LOCATIONS_LIST_SUCCESS
  | typeof FETCH_REMOTE_LOCATIONS_LIST_FAIL
  | typeof CREATE_REMOTE_LOCATION_START
  | typeof CREATE_REMOTE_LOCATION_SUCCESS
  | typeof CREATE_REMOTE_LOCATION_FAIL
  | typeof ASSIGN_LOCATION_REMOTE_LOCATION_START
  | typeof ASSIGN_LOCATION_REMOTE_LOCATION_SUCCESS
  | typeof ASSIGN_LOCATION_REMOTE_LOCATION_FAIL
  | typeof CHANGE_REMOTE_LOCATION_STATUS_ASSIGN_START
  | typeof CHANGE_REMOTE_LOCATION_STATUS_ASSIGN_SUCCESS
  | typeof CHANGE_REMOTE_LOCATION_STATUS_ASSIGN_FAIL
  | typeof CHANGE_REMOTE_LOCATION_STATUS_REJECT_START
  | typeof CHANGE_REMOTE_LOCATION_STATUS_REJECT_SUCCESS
  | typeof CHANGE_REMOTE_LOCATION_STATUS_REJECT_FAIL
  | typeof FETCH_LOCATION_REMOTE_LOCATIONS_START
  | typeof FETCH_LOCATION_REMOTE_LOCATIONS_SUCCESS
  | typeof FETCH_LOCATION_REMOTE_LOCATIONS_FAIL
  | typeof RESET_REMOTE_LOCATION_STORE
  | typeof RESET_CREATED_REMOTE_LOCATION_STORE
  | typeof LOGOUT;
