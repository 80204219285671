import * as actionTypes from './actionTypes';
import { CompanySettingsActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { CompanySettings } from '../../domain/CompanySettings';

export type CompanySettingsStateType = {
  companySettings: CompanySettings | null;
  companySettingsLoading: boolean;
  companySettingsError: HttpError;
  updateCompanySettingsLoading: boolean;
  updateCompanySettingsError: HttpError;
  updateCompanySettingsSuccess: boolean;
};

export type CompanySettingsActionType = CompanySettingsStateType & {
  type: CompanySettingsActionTypes;
};

export const initialState: CompanySettingsStateType = {
  companySettings: null,
  companySettingsLoading: true,
  companySettingsError: null,
  updateCompanySettingsLoading: false,
  updateCompanySettingsSuccess: false,
  updateCompanySettingsError: null,
};

const fetchCompanySettingsStart = (
  state: CompanySettingsStateType,
): CompanySettingsStateType => ({
  ...state,
  companySettingsLoading: true,
});

const fetchCompanySettingsSuccess = (
  state: CompanySettingsStateType,
  action: CompanySettingsActionType,
): CompanySettingsStateType => ({
  ...state,
  companySettingsLoading: false,
  companySettings: action.companySettings,
});

const fetchCompanySettingsFail = (
  state: CompanySettingsStateType,
  action: CompanySettingsActionType,
): CompanySettingsStateType => ({
  ...state,
  companySettingsLoading: false,
  companySettingsError: action.companySettingsError,
});

const setCompanySettingsStart = (
  state: CompanySettingsStateType,
): CompanySettingsStateType => ({
  ...state,
  updateCompanySettingsLoading: true,
});

const setCompanySettingsSuccess = (
  state: CompanySettingsStateType,
): CompanySettingsStateType => ({
  ...state,
  updateCompanySettingsLoading: false,
  updateCompanySettingsSuccess: true,
});

const setCompanySettingsFail = (
  state: CompanySettingsStateType,
  action: CompanySettingsActionType,
): CompanySettingsStateType => ({
  ...state,
  updateCompanySettingsLoading: false,
  updateCompanySettingsError: action.updateCompanySettingsError,
});

const resetCompanySettingsStore = (): CompanySettingsStateType => ({
  ...initialState,
});

const completelyResetCompanySettingsStore = (): CompanySettingsStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CompanySettingsActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_COMPANY_SETTINGS_START:
      return fetchCompanySettingsStart(state);
    case actionTypes.FETCH_COMPANY_SETTINGS_SUCCESS:
      return fetchCompanySettingsSuccess(state, action);
    case actionTypes.FETCH_COMPANY_SETTINGS_FAIL:
      return fetchCompanySettingsFail(state, action);
    case actionTypes.UPDATE_COMPNAY_SETTINGS_START:
      return setCompanySettingsStart(state);
    case actionTypes.UPDATE_COMPNAY_SETTINGS_SUCCESS:
      return setCompanySettingsSuccess(state);
    case actionTypes.UPDATE_COMPNAY_SETTINGS_FAIL:
      return setCompanySettingsFail(state, action);
    case actionTypes.RESET_COMPANY_SETTINGS_STORE:
      return resetCompanySettingsStore();
    case actionTypes.LOGOUT:
      return completelyResetCompanySettingsStore();
    default:
      return state;
  }
};

export default reducer;
