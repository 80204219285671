import { useEffect, useState } from 'react';

export type DelayValue = string | number | number[] | null;

const useDelay = (
  value: DelayValue,
  delay: number,
  onChange: (value: DelayValue, oldValue?: DelayValue) => void = () => {},
): DelayValue => {
  const [delayedValue, setDelayedValue] = useState(value);

  useEffect(() => {
    const delayHandler = setTimeout(() => {
      onChange(value, delayedValue);
      setDelayedValue(value);
    }, delay);

    return (): void => {
      clearTimeout(delayHandler);
    };
  }, [value, delay, onChange, delayedValue]);

  return delayedValue;
};

export default useDelay;
