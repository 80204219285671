import * as actionTypes from './actionTypes';
import { TownAreaActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { TownArea, TownAreasOptions } from '../../domain/TownArea';

export type TownAreaStateType = {
  townAreasList: ListResults<TownArea> | null;
  townAreasListLoading: boolean;
  townAreasListError: HttpError;
  allTownAreasOptions: TownAreasOptions[];
  allTownAreasOptionsLoading: boolean;
  allTownAreasOptionsError: HttpError;
  townAreasListUpdateNeeded: boolean;
  townAreaCreateLoading: boolean;
  townAreaCreateError: HttpError;
  townAreaCreateSuccess: boolean;
  createdTownArea: TownArea | null;
  townAreaUpdateLoading: boolean;
  townAreaUpdateError: HttpError;
  townAreaUpdateSuccess: boolean;
  townArea: TownArea | null;
  townAreaLoading: boolean;
  townAreaError: HttpError;
  townAreaDeleteLoading: boolean;
  townAreaDeleteError: HttpError;
  townAreaDeleteSuccess: boolean;
};

export type TownAreaActionType = TownAreaStateType & {
  type: TownAreaActionTypes;
};

export const initialState: TownAreaStateType = {
  townAreasList: null,
  townAreasListLoading: true,
  townAreasListError: null,
  allTownAreasOptions: [],
  allTownAreasOptionsLoading: false,
  allTownAreasOptionsError: null,
  townAreasListUpdateNeeded: false,
  townAreaCreateLoading: false,
  townAreaCreateError: null,
  townAreaCreateSuccess: false,
  createdTownArea: null,
  townAreaUpdateLoading: false,
  townAreaUpdateError: null,
  townAreaUpdateSuccess: false,
  townArea: null,
  townAreaLoading: false,
  townAreaError: null,
  townAreaDeleteLoading: false,
  townAreaDeleteError: null,
  townAreaDeleteSuccess: false,
};

const fetchTownAreasListStart = (
  state: TownAreaStateType,
): TownAreaStateType => ({
  ...state,
  townAreasListLoading: true,
});

const fetchTownAreasListSuccess = (
  state: TownAreaStateType,
  action: TownAreaActionType,
): TownAreaStateType => ({
  ...state,
  townAreasListLoading: false,
  townAreasList: action.townAreasList,
  townAreasListUpdateNeeded: false,
});

const fetchTownAreasListFail = (
  state: TownAreaStateType,
  action: TownAreaActionType,
): TownAreaStateType => ({
  ...state,
  townAreasListLoading: false,
  townAreasListError: action.townAreasListError,
});

const fetchAllTownAreasOptionsStart = (
  state: TownAreaStateType,
): TownAreaStateType => ({
  ...state,
  allTownAreasOptionsLoading: true,
});

const fetchAllTownAreasOptionsSuccess = (
  state: TownAreaStateType,
  action: TownAreaActionType,
): TownAreaStateType => ({
  ...state,
  allTownAreasOptionsLoading: false,
  allTownAreasOptions: action.allTownAreasOptions,
});

const fetchAllTownAreasOptionsFail = (
  state: TownAreaStateType,
  action: TownAreaActionType,
): TownAreaStateType => ({
  ...state,
  allTownAreasOptionsLoading: false,
  allTownAreasOptionsError: action.allTownAreasOptionsError,
});

const fetchTownAreaStart = (state: TownAreaStateType): TownAreaStateType => ({
  ...state,
  townAreaLoading: true,
});

const fetchTownAreaSuccess = (
  state: TownAreaStateType,
  action: TownAreaActionType,
): TownAreaStateType => ({
  ...state,
  townAreaLoading: false,
  townArea: action.townArea,
});

const fetchTownAreaFail = (
  state: TownAreaStateType,
  action: TownAreaActionType,
): TownAreaStateType => ({
  ...state,
  townAreaLoading: false,
  townAreaError: action.townAreaError,
});

const createTownAreaStart = (state: TownAreaStateType): TownAreaStateType => ({
  ...state,
  townAreaCreateLoading: true,
});

const createTownAreaSuccess = (
  state: TownAreaStateType,
  action: TownAreaActionType,
): TownAreaStateType => ({
  ...state,
  townAreaCreateLoading: false,
  townAreaCreateError: null,
  townAreaCreateSuccess: true,
  townAreasListUpdateNeeded: true,
  createdTownArea: action.createdTownArea,
  townAreasList: state.townAreasList
    ? {
        total: state.townAreasList.total + 1,
        result: [
          ...state.townAreasList.result,
          action.createdTownArea,
        ] as TownArea[],
      }
    : null,
});

const createTownAreaFail = (
  state: TownAreaStateType,
  action: TownAreaActionType,
): TownAreaStateType => ({
  ...state,
  townAreaCreateLoading: false,
  townAreaCreateError: action.townAreaCreateError,
});

const updateTownAreaStart = (state: TownAreaStateType): TownAreaStateType => ({
  ...state,
  townAreaUpdateLoading: true,
});

const updateTownAreaSuccess = (
  state: TownAreaStateType,
): TownAreaStateType => ({
  ...state,
  townAreaUpdateLoading: false,
  townAreaUpdateSuccess: true,
  townAreasListUpdateNeeded: true,
});

const updateTownAreaFail = (
  state: TownAreaStateType,
  action: TownAreaActionType,
): TownAreaStateType => ({
  ...state,
  townAreaUpdateLoading: false,
  townAreaUpdateError: action.townAreaUpdateError,
});

const deleteTownAreaStart = (state: TownAreaStateType): TownAreaStateType => ({
  ...state,
  townAreaDeleteLoading: true,
});

const deleteTownAreaSuccess = (
  state: TownAreaStateType,
): TownAreaStateType => ({
  ...state,
  townAreaDeleteLoading: false,
  townAreaDeleteSuccess: true,
  townAreasListUpdateNeeded: true,
});

const deleteTownAreaFail = (
  state: TownAreaStateType,
  action: TownAreaActionType,
): TownAreaStateType => ({
  ...state,
  townAreaDeleteLoading: false,
  townAreaDeleteError: action.townAreaDeleteError,
});

const resetCreatedTownAreaStore = (
  state: TownAreaStateType,
): TownAreaStateType => ({
  ...state,
  townAreaCreateLoading: false,
  townAreaCreateError: null,
  townAreaCreateSuccess: false,
  createdTownArea: null,
});

const resetTownAreaStore = (): TownAreaStateType => ({
  ...initialState,
});

const completelyResetTownAreaStore = (): TownAreaStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: TownAreaActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_TOWN_AREAS_LIST_START:
      return fetchTownAreasListStart(state);
    case actionTypes.FETCH_TOWN_AREAS_LIST_SUCCESS:
      return fetchTownAreasListSuccess(state, action);
    case actionTypes.FETCH_TOWN_AREAS_LIST_FAIL:
      return fetchTownAreasListFail(state, action);
    case actionTypes.FETCH_ALL_TOWN_AREAS_OPTIONS_START:
      return fetchAllTownAreasOptionsStart(state);
    case actionTypes.FETCH_ALL_TOWN_AREAS_OPTIONS_SUCCESS:
      return fetchAllTownAreasOptionsSuccess(state, action);
    case actionTypes.FETCH_ALL_TOWN_AREAS_OPTIONS_FAIL:
      return fetchAllTownAreasOptionsFail(state, action);
    case actionTypes.FETCH_TOWN_AREA_START:
      return fetchTownAreaStart(state);
    case actionTypes.FETCH_TOWN_AREA_SUCCESS:
      return fetchTownAreaSuccess(state, action);
    case actionTypes.FETCH_TOWN_AREA_FAIL:
      return fetchTownAreaFail(state, action);
    case actionTypes.CREATE_TOWN_AREA_START:
      return createTownAreaStart(state);
    case actionTypes.CREATE_TOWN_AREA_SUCCESS:
      return createTownAreaSuccess(state, action);
    case actionTypes.CREATE_TOWN_AREA_FAIL:
      return createTownAreaFail(state, action);
    case actionTypes.UPDATE_TOWN_AREA_START:
      return updateTownAreaStart(state);
    case actionTypes.UPDATE_TOWN_AREA_SUCCESS:
      return updateTownAreaSuccess(state);
    case actionTypes.UPDATE_TOWN_AREA_FAIL:
      return updateTownAreaFail(state, action);
    case actionTypes.DELETE_TOWN_AREA_START:
      return deleteTownAreaStart(state);
    case actionTypes.DELETE_TOWN_AREA_SUCCESS:
      return deleteTownAreaSuccess(state);
    case actionTypes.DELETE_TOWN_AREA_FAIL:
      return deleteTownAreaFail(state, action);
    case actionTypes.RESET_TOWN_AREA_STORE:
      return resetTownAreaStore();
    case actionTypes.RESET_CREATED_TOWN_AREA_STORE:
      return resetCreatedTownAreaStore(state);
    case actionTypes.LOGOUT:
      return completelyResetTownAreaStore();
    default:
      return state;
  }
};

export default reducer;
