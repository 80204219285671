import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  fetchPriceNotificationsListFail,
  fetchPriceNotificationsListStart,
  fetchPriceNotificationsListSuccess,
  updatePriceNotificationIsSeenStatusFail,
  updatePriceNotificationIsSeenStatusStart,
  updatePriceNotificationIsSeenStatusSuccess,
} from './actions';
import { showToast } from '../../utility/toast/toast';
import { IntlShape } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';

const API_URL = '/price-notifications';

export const fetchPriceNotificationsList =
  (params: ListParams, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchPriceNotificationsListStart());
    return axios
      .get(API_URL, { params })
      .then((response) => {
        dispatch(fetchPriceNotificationsListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchPriceNotificationsListFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const updatePriceNotificationIsSeenStatus =
  (priceNotificationId: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updatePriceNotificationIsSeenStatusStart());
    return axios
      .patch(`${API_URL}/${priceNotificationId}`)
      .then(() => {
        dispatch(updatePriceNotificationIsSeenStatusSuccess());
      })
      .catch((err) => {
        dispatch(
          updatePriceNotificationIsSeenStatusFail(err?.response?.data?.message),
        );
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };
