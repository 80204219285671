export const LOGIN_GOOGLE_START = 'LOGIN_GOOGLE_START';
export const LOGIN_GOOGLE_SUCCESS = 'LOGIN_GOOGLE_SUCCESS';
export const LOGIN_GOOGLE_FAIL = 'LOGIN_GOOGLE_FAIL';

export const REFRESH_TOKEN_START = 'REFRESH_TOKEN_START';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAIL = 'REFRESH_TOKEN_FAIL';

export const SELECT_LOCALE = 'SELECT_LOCALE';

export const LOGOUT = 'LOGOUT';

export type AuthActionTypes =
  | typeof LOGIN_GOOGLE_START
  | typeof LOGIN_GOOGLE_SUCCESS
  | typeof LOGIN_GOOGLE_FAIL
  | typeof REFRESH_TOKEN_START
  | typeof REFRESH_TOKEN_SUCCESS
  | typeof REFRESH_TOKEN_FAIL
  | typeof SELECT_LOCALE
  | typeof LOGOUT;
