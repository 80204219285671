import React from 'react';
import { Provider } from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import authReducer, { AuthStateType } from '../../store/auth/reducer';
import userReducer, { UserStateType } from '../../store/user/reducer';
import languageReducer, {
  LanguageStateType,
} from '../../store/language/reducer';
import translationReducer, {
  TranslationStateType,
} from '../../store/translation/reducer';
import instance from '../Axios/axios-instance';
import * as authService from '../../store/auth/actions';
import employeeReducer, {
  EmployeeStateType,
} from '../../store/employee/reducer';
import propertyReducer, {
  PropertyStateType,
} from '../../store/property/reducer';
import amenityReducer, { AmenityStateType } from '../../store/amenity/reducer';
import cityReducer, { CityStateType } from '../../store/city/reducer';
import locationReducer, {
  LocationStateType,
} from '../../store/location/reducer';
import locationPropertyReducer, {
  LocationPropertyStateType,
} from '../../store/locationProperty/reducer';
import integrationReducer, {
  IntegrationStateType,
} from '../../store/integration/reducer';
import companySettingsReducer, {
  CompanySettingsStateType,
} from '../../store/companySettings/reducer';
import propertyOptionReducer, {
  PropertyOptionStateType,
} from '../../store/propertyOption/reducer';
import locationIntegrationReducer, {
  LocationIntegrationStateType,
} from '../../store/locationIntegration/reducer';
import remoteLocationReducer, {
  RemoteLocationStateType,
} from '../../store/remote-location/reducer';
import priceNotificationReducer, {
  PriceNotificationStateType,
} from '../../store/priceNotification/reducer';
import guideReducer, { GuideStateType } from '../../store/guide/reducer';
import enquiryReducer, { EnquiryStateType } from '../../store/enquiry/reducer';
import townAreaReducer, {
  TownAreaStateType,
} from '../../store/townArea/reducer';
import googleReviewReducer, {
  GoogleReviewStateType,
} from '../../store/googleReview/reducer';
import chatGptReducer, { ChatGptStateType } from '../../store/chatGpt/reducer';
import deepLReducer, { DeepLStateType } from '../../store/deepL/reducer';

export type StoreState = {
  auth: AuthStateType;
  user: UserStateType;
  translation: TranslationStateType;
  language: LanguageStateType;
  employee: EmployeeStateType;
  property: PropertyStateType;
  amenity: AmenityStateType;
  city: CityStateType;
  location: LocationStateType;
  locationProperty: LocationPropertyStateType;
  integration: IntegrationStateType;
  locationIntegration: LocationIntegrationStateType;
  companySettings: CompanySettingsStateType;
  propertyOption: PropertyOptionStateType;
  remoteLocation: RemoteLocationStateType;
  priceNotification: PriceNotificationStateType;
  guide: GuideStateType;
  enquiry: EnquiryStateType;
  townArea: TownAreaStateType;
  googleReview: GoogleReviewStateType;
  chatGpt: ChatGptStateType;
  deepL: DeepLStateType;
};

type Props = {
  children?: any;
};

const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  user: userReducer,
  translation: translationReducer,
  language: languageReducer,
  employee: employeeReducer,
  property: propertyReducer,
  amenity: amenityReducer,
  city: cityReducer,
  location: locationReducer,
  locationProperty: locationPropertyReducer,
  integration: integrationReducer,
  locationIntegration: locationIntegrationReducer,
  companySettings: companySettingsReducer,
  propertyOption: propertyOptionReducer,
  remoteLocation: remoteLocationReducer,
  priceNotification: priceNotificationReducer,
  guide: guideReducer,
  enquiry: enquiryReducer,
  townArea: townAreaReducer,
  googleReview: googleReviewReducer,
  chatGpt: chatGptReducer,
  deepL: deepLReducer,
});

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store: Store<any, any> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

const { dispatch } = store;
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.config.url !== '/token'
    ) {
      dispatch(authService.logout());
    }

    return Promise.reject(error);
  },
);

const StoreProvider = ({ children }: Props) => (
  <Provider store={store}>{children}</Provider>
);

export default StoreProvider;
