import * as actionTypes from './actionTypes';
import { PropertyOptionActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { PropertyOption } from '../../domain/PropertyOption';

export type PropertyOptionStateType = {
  allPropertyOptions: PropertyOption[];
  allPropertyOptionsLoading: boolean;
  allPropertyOptionsError: HttpError;
};

export type PropertyOptionActionType = PropertyOptionStateType & {
  type: PropertyOptionActionTypes;
};

export const initialState: PropertyOptionStateType = {
  allPropertyOptions: [],
  allPropertyOptionsLoading: false,
  allPropertyOptionsError: null,
};

const fetchAllPropertyOptionsStart = (
  state: PropertyOptionStateType,
): PropertyOptionStateType => ({
  ...state,
  allPropertyOptionsLoading: true,
});

const fetchAllPropertyOptionsSuccess = (
  state: PropertyOptionStateType,
  action: PropertyOptionActionType,
): PropertyOptionStateType => ({
  ...state,
  allPropertyOptionsLoading: false,
  allPropertyOptions: action.allPropertyOptions,
});

const fetchAllPropertyOptionsFail = (
  state: PropertyOptionStateType,
  action: PropertyOptionActionType,
): PropertyOptionStateType => ({
  ...state,
  allPropertyOptionsLoading: false,
  allPropertyOptionsError: action.allPropertyOptionsError,
});

const resetPropertyOptionStore = (): PropertyOptionStateType => ({
  ...initialState,
});

const completelyResetPropertyOptionStore = (): PropertyOptionStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: PropertyOptionActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ALL_PROPERTY_OPTIONS_START:
      return fetchAllPropertyOptionsStart(state);
    case actionTypes.FETCH_ALL_PROPERTY_OPTIONS_SUCCESS:
      return fetchAllPropertyOptionsSuccess(state, action);
    case actionTypes.FETCH_ALL_PROPERTY_OPTIONS_FAIL:
      return fetchAllPropertyOptionsFail(state, action);
    case actionTypes.RESET_PROPERTY_OPTION_STORE:
      return resetPropertyOptionStore();
    case actionTypes.LOGOUT:
      return completelyResetPropertyOptionStore();
    default:
      return state;
  }
};

export default reducer;
