import * as actionTypes from './actionTypes';
import { EnquiryActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Enquiry } from '../../domain/Enquiry';

export type EnquiryStateType = {
  enquiriesList: ListResults<Enquiry> | null;
  enquiriesListLoading: boolean;
  enquiriesListError: HttpError;
  enquiryCreateLoading: boolean;
  enquiryCreateError: HttpError | null;
  enquiryCreateSuccess: boolean;
  propertyEnquiryCreateLoading: boolean;
  propertyEnquiryCreateError: HttpError | null;
  propertyEnquiryCreateSuccess: boolean;
};

export type EnquiryActionType = EnquiryStateType & {
  type: EnquiryActionTypes;
};

export const initialState: EnquiryStateType = {
  enquiriesList: null,
  enquiriesListLoading: true,
  enquiriesListError: null,
  enquiryCreateLoading: false,
  enquiryCreateError: null,
  enquiryCreateSuccess: false,
  propertyEnquiryCreateLoading: false,
  propertyEnquiryCreateError: null,
  propertyEnquiryCreateSuccess: false,
};

const fetchEnquiriesListStart = (
  state: EnquiryStateType,
): EnquiryStateType => ({
  ...state,
  enquiriesListLoading: true,
});

const fetchEnquiriesListSuccess = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  enquiriesListLoading: false,
  enquiriesList: action.enquiriesList,
});

const fetchEnquiriesListFail = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  enquiriesListLoading: false,
  enquiriesListError: action.enquiriesListError,
});

const createEnquiryStart = (state: EnquiryStateType): EnquiryStateType => ({
  ...state,
  enquiryCreateLoading: true,
});

const createEnquirySuccess = (state: EnquiryStateType): EnquiryStateType => ({
  ...state,
  enquiryCreateLoading: false,
  enquiryCreateError: null,
  enquiryCreateSuccess: true,
});

const createEnquiryFail = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  enquiryCreateLoading: false,
  enquiryCreateError: action.enquiryCreateError,
});

const createPropertyEnquiryStart = (
  state: EnquiryStateType,
): EnquiryStateType => ({
  ...state,
  propertyEnquiryCreateLoading: true,
});

const createPropertyEnquirySuccess = (
  state: EnquiryStateType,
): EnquiryStateType => ({
  ...state,
  propertyEnquiryCreateLoading: false,
  propertyEnquiryCreateError: null,
  propertyEnquiryCreateSuccess: true,
});

const createPropertyEnquiryFail = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  propertyEnquiryCreateLoading: false,
  propertyEnquiryCreateError: action.propertyEnquiryCreateError,
});

const resetEnquiryStore = (): EnquiryStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: EnquiryActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ENQUIRIES_LIST_START:
      return fetchEnquiriesListStart(state);
    case actionTypes.FETCH_ENQUIRIES_LIST_SUCCESS:
      return fetchEnquiriesListSuccess(state, action);
    case actionTypes.FETCH_ENQUIRIES_LIST_FAIL:
      return fetchEnquiriesListFail(state, action);
    case actionTypes.CREATE_ENQUIRY_START:
      return createEnquiryStart(state);
    case actionTypes.CREATE_ENQUIRY_SUCCESS:
      return createEnquirySuccess(state);
    case actionTypes.CREATE_ENQUIRY_FAIL:
      return createEnquiryFail(state, action);
    case actionTypes.CREATE_PROPERTY_ENQUIRY_START:
      return createPropertyEnquiryStart(state);
    case actionTypes.CREATE_PROPERTY_ENQUIRY_SUCCESS:
      return createPropertyEnquirySuccess(state);
    case actionTypes.CREATE_PROPERTY_ENQUIRY_FAIL:
      return createPropertyEnquiryFail(state, action);
    case actionTypes.RESET_ENQUIRY_STORE:
      return resetEnquiryStore();
    case actionTypes.LOGOUT:
      return resetEnquiryStore();
    default:
      return state;
  }
};

export default reducer;
