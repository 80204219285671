import React from 'react';
import styles from './ContactBox.module.scss';
import saveIcon from '../../../assets/icons/heart-empty2.svg';
import savedIcon from '../../../assets/icons/heart-full2.svg';
import phoneIcon from '../../../assets/icons/phone-icon.svg';
import emailIcon from '../../../assets/icons/email-icon1.svg';
import { connect } from 'react-redux';
import { StoreState } from '../../../config/StoreProvider/StoreProvider';
import { PublicLocation } from '../../../domain/PublicLocation';
import { useIntl } from 'react-intl';
import { translate } from '../../../utility/messageTranslator/translate';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../config/Router/routes';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { setPublicScrollToId } from '../../../store/location/actions';
import { NavigationScrollId } from '../../../store/location/reducer';

type Props = {
  publicSavedLocations: PublicLocation[];
  onSetPublicScrollToId: (navigationScrollId: NavigationScrollId) => void;
};

const ContactBox = ({ publicSavedLocations, onSetPublicScrollToId }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <div className={styles.stickyContactBox}>
      <div className={styles.contactBoxContent}>
        <div className={styles.iconBox}>
          <a
            href={`https://wa.me/${translate(intl, 'CONTACT.CARD_PHONE')
              .replaceAll('(', '')
              .replaceAll(')', '')
              .replaceAll(' ', '')}`}
            target={'_blank'}
            rel="noreferrer noopener"
          >
            <img src={phoneIcon} />
          </a>
        </div>
        <div
          className={styles.iconBox}
          role="button"
          onClick={() => {
            onSetPublicScrollToId(NavigationScrollId.CONTACT_FORM);
            navigate(routes.contact);
          }}
        >
          <img src={emailIcon} />
        </div>
        <div
          className={styles.iconBox}
          role="button"
          onClick={() => {
            onSetPublicScrollToId(NavigationScrollId.FAVORITE_LOCATIONS);
            navigate(routes.contact);
          }}
        >
          <img
            src={publicSavedLocations?.length === 0 ? saveIcon : savedIcon}
          />
          {publicSavedLocations?.length ?? 0}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  publicSavedLocations: state.location.publicSavedLocations,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onSetPublicScrollToId: (navigationScrollId: NavigationScrollId) =>
    dispatch(setPublicScrollToId(navigationScrollId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactBox);
