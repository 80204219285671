import React, { useEffect, useState } from 'react';
import styles from './Navigation.module.scss';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { IconButton, Popover } from '@mui/material';
import {
  faBars,
  faSignOutAlt,
  faTimes,
  faCog,
  faArrowRight,
  faBell,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as authService from '../../store/auth/service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../config/Router/routes';
import { IntlShape, useIntl } from 'react-intl';
import cx from 'classnames';
import { PriceNotification } from '../../domain/PriceNotification';
import { ListResults } from '../List/List';
import { ListParams } from '../../hooks/useList/useList';
import * as priceNotificationService from '../../store/priceNotification/service';
import { resetPriceNotificationStore } from '../../store/priceNotification/actions';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';
import { translate } from '../../utility/messageTranslator/translate';
import { FormattedPrice } from '../FormattedPrice/FormattedPrice';

export type Props = {
  onLogout: () => void;
  onDrawerClick: () => void;
  isMobileMenuOpen: boolean;
  onFetchPriceNotificationsList: (
    listParams: ListParams,
    intl: IntlShape,
  ) => void;
  onResetPriceNotificationStore: () => void;
  onUpdatePriceNotificationIsSeenStatus: (
    priceNotificationId: number,
    intl: IntlShape,
  ) => void;
  priceNotificationsList: ListResults<PriceNotification> | null;
  isPriceNotificationsUpdateNeeded: boolean;
  isAuthenticated: boolean;
  currentUser: User | null;
};

const MOBILE_BREAK_POINT = 900;

export const Navigation = ({
  onLogout,
  onDrawerClick,
  isMobileMenuOpen,
  onFetchPriceNotificationsList,
  onResetPriceNotificationStore,
  onUpdatePriceNotificationIsSeenStatus,
  priceNotificationsList,
  isPriceNotificationsUpdateNeeded,
  isAuthenticated,
  currentUser,
}: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const { width } = useWindowSize();
  const navigate = useNavigate();
  const intl = useIntl();

  const PRICE_NOTIFICATIONS_LIST_PARAMS: ListParams = {
    sortBy: 'priceNotifications.id',
    search: '',
    sortDirection: 'DESC',
    page: 0,
    limit: 0,
  };

  useEffect(() => {
    if (onResetPriceNotificationStore) {
      return () => onResetPriceNotificationStore();
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && currentUser && currentUser.role === Roles.ADMIN)
      onFetchPriceNotificationsList(PRICE_NOTIFICATIONS_LIST_PARAMS, intl);
  }, []);

  useEffect(() => {
    if (
      isPriceNotificationsUpdateNeeded &&
      isAuthenticated &&
      currentUser &&
      currentUser.role === Roles.ADMIN
    ) {
      onFetchPriceNotificationsList(PRICE_NOTIFICATIONS_LIST_PARAMS, intl);
    }
  }, [isPriceNotificationsUpdateNeeded]);

  const handleNotificationClick = (priceNotification: PriceNotification) => {
    setIsPopoverOpen(false);
    onUpdatePriceNotificationIsSeenStatus(priceNotification.id, intl);
    navigate(
      routes.locations.edit.replace(
        ':id',
        `${priceNotification.remoteLocation.locationId.toString()}?tabId=1&search=${
          priceNotification.remoteLocation.name
        }`,
      ),
    );
  };

  const mapPriceNotifications = () => {
    if (!priceNotificationsList) {
      return [];
    }

    return priceNotificationsList.result.map((notification, index) => (
      <li
        className={cx(styles.notificationPopoverListItem, {
          [styles.isSeen]: notification.isSeen,
        })}
        key={index}
        onClick={() => handleNotificationClick(notification)}
      >
        <div className={styles.notificationPopoverItemLocationName}>
          {notification.remoteLocation?.location?.name}
        </div>

        <div className={styles.notificationPopoverItemRemoteLocationName}>
          {notification.remoteLocation.name}
        </div>

        <div className={styles.notificationPopoverItemPrice}>
          <del>{notification.previousPrice} €</del>
          <FontAwesomeIcon
            icon={faArrowRight as IconProp}
            fixedWidth
            size="sm"
          />
          <FormattedPrice price={notification.remoteLocation.price} />
        </div>

        <div className={styles.notificationPopoverItemIntegrationName}>
          {notification.remoteLocation?.integration?.name}
        </div>
      </li>
    ));
  };

  const priceNotificationsBadge = () => {
    if (!priceNotificationsList) {
      return;
    }

    const notSeenPriceNotificationsAmount =
      priceNotificationsList.result.filter(
        (priceNotification) => !priceNotification.isSeen,
      ).length;

    if (!notSeenPriceNotificationsAmount) {
      return;
    }

    return (
      <div className={styles.priceNotificationsBadge}>
        {notSeenPriceNotificationsAmount}
      </div>
    );
  };

  return (
    <>
      <header className={styles.navigationContainer}>
        <div className={styles.iconItems}>
          {width && width < MOBILE_BREAK_POINT && (
            <IconButton onClick={() => onDrawerClick()}>
              <FontAwesomeIcon
                icon={(isMobileMenuOpen ? faTimes : faBars) as IconProp}
                fixedWidth
                size="sm"
              />
            </IconButton>
          )}
        </div>
        <div className={styles.iconItems}>
          <IconButton
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setIsPopoverOpen(true);
            }}
          >
            <FontAwesomeIcon
              className={styles.priceNotificationIcon}
              icon={faBell as IconProp}
              fixedWidth
              size="sm"
            />
            {priceNotificationsBadge()}
          </IconButton>

          <Popover
            open={isPopoverOpen}
            onClose={() => setIsPopoverOpen(false)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <div className={styles.notificationPopoverContent}>
              <ul className={styles.notificationPopoverList}>
                {priceNotificationsList?.total ? (
                  mapPriceNotifications()
                ) : (
                  <li
                    className={cx(
                      styles.notificationPopoverListItem,
                      styles.isSeen,
                    )}
                  >
                    {translate(intl, 'PRICE_NOTIFICATION.NO_NOTIFICATIONS')}
                  </li>
                )}
              </ul>
            </div>
          </Popover>

          <IconButton onClick={() => navigate(routes.companySettings)}>
            <FontAwesomeIcon icon={faCog as IconProp} fixedWidth size="sm" />
          </IconButton>
          <IconButton onClick={() => onLogout()}>
            <FontAwesomeIcon
              icon={faSignOutAlt as IconProp}
              fixedWidth
              size="sm"
            />
          </IconButton>
        </div>
      </header>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  priceNotificationsList: state.priceNotification.priceNotificationsList,
  isPriceNotificationsUpdateNeeded:
    state.priceNotification.priceNotificationsListUpdateNeeded,
  selectedLocale: state.auth.selectedLocale,
  languages: state.language.languages,
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onFetchPriceNotificationsList: (tableParams: ListParams, intl: IntlShape) =>
    dispatch(
      priceNotificationService.fetchPriceNotificationsList(tableParams, intl),
    ),
  onUpdatePriceNotificationIsSeenStatus: (
    priceNotificationId: number,
    intl: IntlShape,
  ) =>
    dispatch(
      priceNotificationService.updatePriceNotificationIsSeenStatus(
        priceNotificationId,
        intl,
      ),
    ),
  onResetPriceNotificationStore: () => dispatch(resetPriceNotificationStore()),
  onLogout: () => dispatch(authService.logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
