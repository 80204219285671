import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/counter.css';

if (process.env.REACT_APP_SENTRY_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://c8fb120b1efc42c98d96e0fbab0d6546@o1101437.ingest.sentry.io/4505318803832832',
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENV,
    debug: true,
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(<App />);

reportWebVitals();
