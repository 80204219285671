export const FETCH_LOCATION_INTEGRATION_LIST_START =
  'FETCH_LOCATION_INTEGRATION_LIST_START';
export const FETCH_LOCATION_INTEGRATION_LIST_SUCCESS =
  'FETCH_LOCATION_INTEGRATION_LIST_SUCCESS';
export const FETCH_LOCATION_INTEGRATION_LIST_FAIL =
  'FETCH_LOCATION_INTEGRATION_LIST_FAIL';

export const TOGGLE_LOCATION_INTEGRATION_START =
  'TOGGLE_LOCATION_INTEGRATION_START';
export const TOGGLE_LOCATION_INTEGRATION_SUCCESS =
  'TOGGLE_LOCATION_INTEGRATION_SUCCESS';
export const TOGGLE_LOCATION_INTEGRATION_FAIL =
  'TOGGLE_LOCATION_INTEGRATION_FAIL';

export const RESET_LOCATION_INTEGRATION_STORE =
  'RESET_LOCATION_INTEGRATION_STORE';

export const LOGOUT = 'LOGOUT';

export type LocationIntegrationActionTypes =
  | typeof FETCH_LOCATION_INTEGRATION_LIST_START
  | typeof FETCH_LOCATION_INTEGRATION_LIST_SUCCESS
  | typeof FETCH_LOCATION_INTEGRATION_LIST_FAIL
  | typeof TOGGLE_LOCATION_INTEGRATION_START
  | typeof TOGGLE_LOCATION_INTEGRATION_SUCCESS
  | typeof TOGGLE_LOCATION_INTEGRATION_FAIL
  | typeof RESET_LOCATION_INTEGRATION_STORE
  | typeof LOGOUT;
