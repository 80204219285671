export const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LANGUAGE;
export const DEFAULT_ADMIN_LOCALE = 'en';
export const NO_INFO = '-';

export const PropertyPageMaxDescriptionLength = 1000;

export const HOMEPAGE_TOP_LOCATIONS_LIMIT = 3;
export const ABOUT_US_PAGE_TOP_LOCATIONS_LIMIT = 6;

export const LOCAL_STORAGE_PUBLIC_SAVED_LOCATIONS = 'publicSavedLocations';
