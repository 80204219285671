export const routes = {
  homepage: '/',
  propertiesListPage: '/properties-list',
  property: '/property/:slug',
  aboutUsPage: '/about-us',
  servicesPage: '/services',
  guidesPage: '/guides',
  login: '/login',
  profile: '/profile',
  dynamicPage: '/:locale/:slug',
  admin: '/admin',
  contact: '/contact',
  users: {
    list: '/admin/users',
    create: '/admin/users/new',
    edit: '/admin/users/:id',
  },
  employees: {
    list: '/admin/employees',
    edit: '/admin/employees/:id',
  },
  guides: {
    list: '/admin/guides',
    create: '/admin/guides/new',
    edit: '/admin/guides/:id',
  },
  enquiries: {
    list: '/admin/enquiries',
  },
  properties: {
    list: '/admin/properties',
  },
  amenities: {
    list: '/admin/amenities',
  },
  cities: {
    list: '/admin/cities',
  },
  townAreas: {
    list: '/admin/town-areas',
  },
  locations: {
    list: '/admin/locations',
    create: '/admin/locations/new',
    edit: '/admin/locations/:id',
  },
  integrations: {
    list: '/admin/integrations',
  },
  remoteLocations: {
    list: '/admin/remote-locations',
  },
  translations: '/admin/translations',
  companySettings: '/admin/company-settings',
  languages: {
    list: '/admin/languages',
  },
};
