export const FETCH_LOCATION_PROPERTY_LIST_START =
  'FETCH_LOCATION_PROPERTY_LIST_START';
export const FETCH_LOCATION_PROPERTY_LIST_SUCCESS =
  'FETCH_LOCATION_PROPERTY_LIST_SUCCESS';
export const FETCH_LOCATION_PROPERTY_LIST_FAIL =
  'FETCH_LOCATION_PROPERTY_LIST_FAIL';

export const FETCH_LOCATION_PROPERTY_START = 'FETCH_LOCATION_PROPERTY_START';
export const FETCH_LOCATION_PROPERTY_SUCCESS =
  'FETCH_LOCATION_PROPERTY_SUCCESS';
export const FETCH_LOCATION_PROPERTY_FAIL = 'FETCH_LOCATION_PROPERTY_FAIL';

export const CREATE_LOCATION_PROPERTY_START = 'CREATE_LOCATION_PROPERTY_START';
export const CREATE_LOCATION_PROPERTY_SUCCESS =
  'CREATE_LOCATION_PROPERTY_SUCCESS';
export const CREATE_LOCATION_PROPERTY_FAIL = 'CREATE_LOCATION_PROPERTY_FAIL';

export const UPDATE_LOCATION_PROPERTY_START = 'UPDATE_LOCATION_PROPERTY_START';
export const UPDATE_LOCATION_PROPERTY_SUCCESS =
  'UPDATE_LOCATION_PROPERTY_SUCCESS';
export const UPDATE_LOCATION_PROPERTY_FAIL = 'UPDATE_LOCATION_PROPERTY_FAIL';

export const DELETE_LOCATION_PROPERTY_START = 'DELETE_LOCATION_PROPERTY_START';
export const DELETE_LOCATION_PROPERTY_SUCCESS =
  'DELETE_LOCATION_PROPERTY_SUCCESS';
export const DELETE_LOCATION_PROPERTY_FAIL = 'DELETE_LOCATION_PROPERTY_FAIL';

export const RESET_CREATED_LOCATION_PROPERTY_STORE =
  'RESET_CREATED_LOCATION_PROPERTY_STORE';
export const RESET_LOCATION_PROPERTY_STORE = 'RESET_LOCATION_PROPERTY_STORE';

export const LOGOUT = 'LOGOUT';

export type LocationPropertyActionTypes =
  | typeof FETCH_LOCATION_PROPERTY_LIST_START
  | typeof FETCH_LOCATION_PROPERTY_LIST_SUCCESS
  | typeof FETCH_LOCATION_PROPERTY_LIST_FAIL
  | typeof FETCH_LOCATION_PROPERTY_START
  | typeof FETCH_LOCATION_PROPERTY_SUCCESS
  | typeof FETCH_LOCATION_PROPERTY_FAIL
  | typeof CREATE_LOCATION_PROPERTY_START
  | typeof CREATE_LOCATION_PROPERTY_SUCCESS
  | typeof CREATE_LOCATION_PROPERTY_FAIL
  | typeof UPDATE_LOCATION_PROPERTY_START
  | typeof UPDATE_LOCATION_PROPERTY_SUCCESS
  | typeof UPDATE_LOCATION_PROPERTY_FAIL
  | typeof DELETE_LOCATION_PROPERTY_START
  | typeof DELETE_LOCATION_PROPERTY_SUCCESS
  | typeof DELETE_LOCATION_PROPERTY_FAIL
  | typeof RESET_LOCATION_PROPERTY_STORE
  | typeof RESET_CREATED_LOCATION_PROPERTY_STORE
  | typeof LOGOUT;
