import * as actionTypes from './actionTypes';
import { GuideActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Guide } from '../../domain/Guide';
import { PublicGuide } from '../../domain/PublicGuide';

export type GuideStateType = {
  guidesList: ListResults<Guide> | null;
  guidesListLoading: boolean;
  guidesListError: HttpError;
  guidesListUpdateNeeded: boolean;
  guideCreateLoading: boolean;
  guideCreateError: HttpError;
  guideCreateSuccess: boolean;
  createdGuide: Guide | null;
  guideUpdateLoading: boolean;
  guideUpdateError: HttpError;
  guideUpdateSuccess: boolean;
  guide: Guide | null;
  guideLoading: boolean;
  guideError: HttpError;
  guideUpdateNeeded: boolean;
  guideDeleteLoading: boolean;
  guideDeleteError: HttpError;
  guideDeleteSuccess: boolean;
  publicGuides: PublicGuide[];
  publicGuidesLoading: boolean;
  publicGuidesError: HttpError;
};

export type GuideActionType = GuideStateType & {
  type: GuideActionTypes;
};

export const initialState: GuideStateType = {
  guidesList: null,
  guidesListLoading: true,
  guidesListError: null,
  guidesListUpdateNeeded: false,
  guideCreateLoading: false,
  guideCreateError: null,
  guideCreateSuccess: false,
  createdGuide: null,
  guideUpdateLoading: false,
  guideUpdateError: null,
  guideUpdateSuccess: false,
  guide: null,
  guideLoading: false,
  guideError: null,
  guideUpdateNeeded: false,
  guideDeleteLoading: false,
  guideDeleteError: null,
  guideDeleteSuccess: false,
  publicGuides: [],
  publicGuidesLoading: false,
  publicGuidesError: null,
};

const fetchGuideListStart = (state: GuideStateType): GuideStateType => ({
  ...state,
  guidesListLoading: true,
});

const fetchGuideListSuccess = (
  state: GuideStateType,
  action: GuideActionType,
): GuideStateType => ({
  ...state,
  guidesListLoading: false,
  guidesList: action.guidesList,
  guidesListUpdateNeeded: false,
});

const fetchGuideListFail = (
  state: GuideStateType,
  action: GuideActionType,
): GuideStateType => ({
  ...state,
  guidesListLoading: false,
  guidesListError: action.guidesListError,
});

const fetchGuideStart = (state: GuideStateType): GuideStateType => ({
  ...state,
  guideLoading: true,
});

const fetchGuideSuccess = (
  state: GuideStateType,
  action: GuideActionType,
): GuideStateType => ({
  ...state,
  guideLoading: false,
  guideUpdateNeeded: false,
  guide: action.guide,
});

const fetchGuideFail = (
  state: GuideStateType,
  action: GuideActionType,
): GuideStateType => ({
  ...state,
  guideLoading: false,
  guideUpdateNeeded: false,
  guideError: action.guideError,
});

const createGuideStart = (state: GuideStateType): GuideStateType => ({
  ...state,
  guideCreateLoading: true,
});

const createGuideSuccess = (
  state: GuideStateType,
  action: GuideActionType,
): GuideStateType => ({
  ...state,
  guideCreateLoading: false,
  guideCreateError: null,
  guideCreateSuccess: true,
  guidesListUpdateNeeded: true,
  createdGuide: action.createdGuide,
  guidesList: state.guidesList
    ? {
        total: state.guidesList.total + 1,
        result: [...state.guidesList.result, action.createdGuide] as Guide[],
      }
    : null,
});

const createGuideFail = (
  state: GuideStateType,
  action: GuideActionType,
): GuideStateType => ({
  ...state,
  guideCreateLoading: false,
  guideCreateError: action.guideCreateError,
});

const updateGuideStart = (state: GuideStateType): GuideStateType => ({
  ...state,
  guideUpdateLoading: true,
});

const updateGuideSuccess = (state: GuideStateType): GuideStateType => ({
  ...state,
  guideUpdateLoading: false,
  guideUpdateSuccess: true,
  guidesListUpdateNeeded: true,
});

const updateGuideFail = (
  state: GuideStateType,
  action: GuideActionType,
): GuideStateType => ({
  ...state,
  guideUpdateLoading: false,
  guideUpdateError: action.guideUpdateError,
});

const deleteGuideStart = (state: GuideStateType): GuideStateType => ({
  ...state,
  guideDeleteLoading: true,
});

const deleteGuideSuccess = (state: GuideStateType): GuideStateType => ({
  ...state,
  guideDeleteLoading: false,
  guideDeleteSuccess: true,
  guidesListUpdateNeeded: true,
});

const deleteGuideFail = (
  state: GuideStateType,
  action: GuideActionType,
): GuideStateType => ({
  ...state,
  guideDeleteLoading: false,
  guideDeleteError: action.guideDeleteError,
});

const fetchPublicGuidesStart = (state: GuideStateType): GuideStateType => ({
  ...state,
  publicGuidesLoading: true,
});

const fetchPublicGuidesSuccess = (
  state: GuideStateType,
  action: GuideActionType,
): GuideStateType => ({
  ...state,
  publicGuidesLoading: false,
  publicGuides: action.publicGuides,
});

const fetchPublicGuidesFail = (
  state: GuideStateType,
  action: GuideActionType,
): GuideStateType => ({
  ...state,
  publicGuidesLoading: false,
  publicGuidesError: action.publicGuidesError,
});

const resetCreatedGuideStore = (state: GuideStateType): GuideStateType => ({
  ...state,
  guideCreateLoading: false,
  guideCreateError: null,
  guideCreateSuccess: false,
  createdGuide: null,
});

const resetGuideError = (state: GuideStateType): GuideStateType => ({
  ...state,
  guideCreateError: null,
  guideUpdateError: null,
});

const resetGuideStore = (): GuideStateType => ({
  ...initialState,
});

const completelyResetGuideStore = (): GuideStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: GuideActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_GUIDES_LIST_START:
      return fetchGuideListStart(state);
    case actionTypes.FETCH_GUIDES_LIST_SUCCESS:
      return fetchGuideListSuccess(state, action);
    case actionTypes.FETCH_GUIDES_LIST_FAIL:
      return fetchGuideListFail(state, action);
    case actionTypes.FETCH_GUIDE_START:
      return fetchGuideStart(state);
    case actionTypes.FETCH_GUIDE_SUCCESS:
      return fetchGuideSuccess(state, action);
    case actionTypes.FETCH_GUIDE_FAIL:
      return fetchGuideFail(state, action);
    case actionTypes.FETCH_PUBLIC_GUIDES_START:
      return fetchPublicGuidesStart(state);
    case actionTypes.FETCH_PUBLIC_GUIDES_SUCCESS:
      return fetchPublicGuidesSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_GUIDES_FAIL:
      return fetchPublicGuidesFail(state, action);
    case actionTypes.CREATE_GUIDE_START:
      return createGuideStart(state);
    case actionTypes.CREATE_GUIDE_SUCCESS:
      return createGuideSuccess(state, action);
    case actionTypes.CREATE_GUIDE_FAIL:
      return createGuideFail(state, action);
    case actionTypes.UPDATE_GUIDE_START:
      return updateGuideStart(state);
    case actionTypes.UPDATE_GUIDE_SUCCESS:
      return updateGuideSuccess(state);
    case actionTypes.UPDATE_GUIDE_FAIL:
      return updateGuideFail(state, action);
    case actionTypes.DELETE_GUIDE_START:
      return deleteGuideStart(state);
    case actionTypes.DELETE_GUIDE_SUCCESS:
      return deleteGuideSuccess(state);
    case actionTypes.DELETE_GUIDE_FAIL:
      return deleteGuideFail(state, action);
    case actionTypes.RESET_CREATED_GUIDE_STORE:
      return resetCreatedGuideStore(state);
    case actionTypes.RESET_GUIDE_ERROR:
      return resetGuideError(state);
    case actionTypes.RESET_GUIDE_STORE:
      return resetGuideStore();
    case actionTypes.LOGOUT:
      return completelyResetGuideStore();
    default:
      return state;
  }
};

export default reducer;
