import React, { ReactNode } from 'react';
import styles from './Alert.module.scss';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type Props = {
  children?: ReactNode;
  className?: string;
  variant: 'danger' | 'warning' | 'info' | 'success';
  isText?: boolean;
  capitalize?: boolean;
};

const Alert = ({ children, className, variant, isText, capitalize }: Props) => {
  const getIcon = () => {
    if (variant === 'warning') {
      return faExclamationTriangle;
    }

    return faExclamationCircle;
  };

  return (
    <div className={cn(styles.alert, styles[variant], className)}>
      <div className={styles.icon}>
        <FontAwesomeIcon icon={getIcon() as IconProp} fixedWidth size="sm" />
      </div>
      <div className={styles.message}>
        {isText
          ? capitalize
            ? _.capitalize(children?.toString())
            : children?.toString()
          : children}
      </div>
    </div>
  );
};

Alert.defaultProps = {
  isText: true,
  capitalize: true,
};

export default Alert;
