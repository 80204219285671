import * as actionTypes from './actionTypes';
import { LocationPropertyActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { LocationProperty } from '../../domain/LocationProperty';

export type LocationPropertyStateType = {
  locationPropertyList: ListResults<LocationProperty> | null;
  locationPropertyListLoading: boolean;
  locationPropertyListError: HttpError;
  locationPropertyListUpdateNeeded: boolean;
  locationPropertyCreateLoading: boolean;
  locationPropertyCreateError: HttpError;
  locationPropertyCreateSuccess: boolean;
  createdLocationProperty: LocationProperty | null;
  locationPropertyUpdateLoading: boolean;
  locationPropertyUpdateError: HttpError;
  locationPropertyUpdateSuccess: boolean;
  locationProperty: LocationProperty | null;
  locationPropertyLoading: boolean;
  locationPropertyError: HttpError;
  locationPropertyDeleteLoading: boolean;
  locationPropertyDeleteError: HttpError;
  locationPropertyDeleteSuccess: boolean;
};

export type LocationPropertyActionType = LocationPropertyStateType & {
  type: LocationPropertyActionTypes;
};

export const initialState: LocationPropertyStateType = {
  locationPropertyList: null,
  locationPropertyListLoading: true,
  locationPropertyListError: null,
  locationPropertyListUpdateNeeded: false,
  locationPropertyCreateLoading: false,
  locationPropertyCreateError: null,
  locationPropertyCreateSuccess: false,
  createdLocationProperty: null,
  locationPropertyUpdateLoading: false,
  locationPropertyUpdateError: null,
  locationPropertyUpdateSuccess: false,
  locationProperty: null,
  locationPropertyLoading: false,
  locationPropertyError: null,
  locationPropertyDeleteLoading: false,
  locationPropertyDeleteError: null,
  locationPropertyDeleteSuccess: false,
};

const fetchLocationPropertyListStart = (
  state: LocationPropertyStateType,
): LocationPropertyStateType => ({
  ...state,
  locationPropertyListLoading: true,
});

const fetchLocationPropertyListSuccess = (
  state: LocationPropertyStateType,
  action: LocationPropertyActionType,
): LocationPropertyStateType => ({
  ...state,
  locationPropertyListLoading: false,
  locationPropertyList: action.locationPropertyList,
  locationPropertyListUpdateNeeded: false,
});

const fetchLocationPropertyListFail = (
  state: LocationPropertyStateType,
  action: LocationPropertyActionType,
): LocationPropertyStateType => ({
  ...state,
  locationPropertyListLoading: false,
  locationPropertyError: action.locationPropertyError,
});

const fetchLocationPropertyStart = (
  state: LocationPropertyStateType,
): LocationPropertyStateType => ({
  ...state,
  locationPropertyLoading: true,
});

const fetchLocationPropertySuccess = (
  state: LocationPropertyStateType,
  action: LocationPropertyActionType,
): LocationPropertyStateType => ({
  ...state,
  locationPropertyLoading: false,
  locationProperty: action.locationProperty,
});

const fetchLocationPropertyFail = (
  state: LocationPropertyStateType,
  action: LocationPropertyActionType,
): LocationPropertyStateType => ({
  ...state,
  locationPropertyLoading: false,
  locationPropertyError: action.locationPropertyError,
});

const createLocationPropertyStart = (
  state: LocationPropertyStateType,
): LocationPropertyStateType => ({
  ...state,
  locationPropertyCreateLoading: true,
});

const createLocationPropertySuccess = (
  state: LocationPropertyStateType,
  action: LocationPropertyActionType,
): LocationPropertyStateType => ({
  ...state,
  locationPropertyCreateLoading: false,
  locationPropertyCreateError: null,
  locationPropertyCreateSuccess: true,
  locationPropertyListUpdateNeeded: true,
  createdLocationProperty: action.createdLocationProperty,
  locationPropertyList: state.locationPropertyList
    ? {
        total: state.locationPropertyList.total + 1,
        result: [
          ...state.locationPropertyList.result,
          action.locationPropertyList,
        ] as LocationProperty[],
      }
    : null,
});

const createLocationPropertyFail = (
  state: LocationPropertyStateType,
  action: LocationPropertyActionType,
): LocationPropertyStateType => ({
  ...state,
  locationPropertyCreateLoading: false,
  locationPropertyCreateError: action.locationPropertyCreateError,
});

const updateLocationPropertyStart = (
  state: LocationPropertyStateType,
): LocationPropertyStateType => ({
  ...state,
  locationPropertyUpdateLoading: true,
});

const updateLocationPropertySuccess = (
  state: LocationPropertyStateType,
): LocationPropertyStateType => ({
  ...state,
  locationPropertyUpdateLoading: false,
  locationPropertyUpdateSuccess: true,
  locationPropertyListUpdateNeeded: true,
});

const updateLocationPropertyFail = (
  state: LocationPropertyStateType,
  action: LocationPropertyActionType,
): LocationPropertyStateType => ({
  ...state,
  locationPropertyUpdateLoading: false,
  locationPropertyUpdateError: action.locationPropertyUpdateError,
});

const deleteLocationPropertyStart = (
  state: LocationPropertyStateType,
): LocationPropertyStateType => ({
  ...state,
  locationPropertyDeleteLoading: true,
});

const deleteLocationPropertySuccess = (
  state: LocationPropertyStateType,
): LocationPropertyStateType => ({
  ...state,
  locationPropertyDeleteLoading: false,
  locationPropertyDeleteSuccess: true,
  locationPropertyListUpdateNeeded: true,
});

const deleteLocationPropertyFail = (
  state: LocationPropertyStateType,
  action: LocationPropertyActionType,
): LocationPropertyStateType => ({
  ...state,
  locationPropertyDeleteLoading: false,
  locationPropertyDeleteError: action.locationPropertyDeleteError,
});

const resetCreatedLocationPropertyStore = (
  state: LocationPropertyStateType,
): LocationPropertyStateType => ({
  ...state,
  locationPropertyCreateLoading: false,
  locationPropertyCreateError: null,
  locationPropertyCreateSuccess: false,
  createdLocationProperty: null,
});

const resetLocationPropertyStore = (): LocationPropertyStateType => ({
  ...initialState,
});

const completelyResetLocationPropertyStore = (): LocationPropertyStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: LocationPropertyActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_LOCATION_PROPERTY_LIST_START:
      return fetchLocationPropertyListStart(state);
    case actionTypes.FETCH_LOCATION_PROPERTY_LIST_SUCCESS:
      return fetchLocationPropertyListSuccess(state, action);
    case actionTypes.FETCH_LOCATION_PROPERTY_LIST_FAIL:
      return fetchLocationPropertyListFail(state, action);
    case actionTypes.FETCH_LOCATION_PROPERTY_START:
      return fetchLocationPropertyStart(state);
    case actionTypes.FETCH_LOCATION_PROPERTY_SUCCESS:
      return fetchLocationPropertySuccess(state, action);
    case actionTypes.FETCH_LOCATION_PROPERTY_FAIL:
      return fetchLocationPropertyFail(state, action);
    case actionTypes.CREATE_LOCATION_PROPERTY_START:
      return createLocationPropertyStart(state);
    case actionTypes.CREATE_LOCATION_PROPERTY_SUCCESS:
      return createLocationPropertySuccess(state, action);
    case actionTypes.CREATE_LOCATION_PROPERTY_FAIL:
      return createLocationPropertyFail(state, action);
    case actionTypes.UPDATE_LOCATION_PROPERTY_START:
      return updateLocationPropertyStart(state);
    case actionTypes.UPDATE_LOCATION_PROPERTY_SUCCESS:
      return updateLocationPropertySuccess(state);
    case actionTypes.UPDATE_LOCATION_PROPERTY_FAIL:
      return updateLocationPropertyFail(state, action);
    case actionTypes.DELETE_LOCATION_PROPERTY_START:
      return deleteLocationPropertyStart(state);
    case actionTypes.DELETE_LOCATION_PROPERTY_SUCCESS:
      return deleteLocationPropertySuccess(state);
    case actionTypes.DELETE_LOCATION_PROPERTY_FAIL:
      return deleteLocationPropertyFail(state, action);
    case actionTypes.RESET_LOCATION_PROPERTY_STORE:
      return resetLocationPropertyStore();
    case actionTypes.RESET_CREATED_LOCATION_PROPERTY_STORE:
      return resetCreatedLocationPropertyStore(state);
    case actionTypes.LOGOUT:
      return completelyResetLocationPropertyStore();
    default:
      return state;
  }
};

export default reducer;
