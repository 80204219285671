import React, { useEffect } from 'react';
import styles from './Footer.module.scss';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import logo from '../../assets/logo_white.svg';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import iconFacebook from '../../assets/icons/facebook.svg';
import iconInstagram from '../../assets/icons/instagram.svg';
import iconLinkedin from '../../assets/icons/linkedin.svg';
import workingHoursIcon from '../../assets/icons/working-hours-icon.svg';
import addressIcon from '../../assets/icons/address-icon.svg';
import illustration from '../../assets/Illustration.png';
import illustration2 from '../../assets/illustration_2.png';
import { ReactSVG } from 'react-svg';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '../../config/Router/routes';
import { getLocaleUrl } from '../../utility/url/urlHelper';
import { Locale } from '../../domain/Translation';
import { NavigationItem } from '../Layout/Layout';
import cx from 'classnames';
import { HttpError } from '../../config/Axios/axios-instance';
import { useForm } from '../../hooks/useForm/useForm';
import Form from '../../common/Form/Form';
import TextField from '../../common/TextField/TextField';
import Button from '../../common/Button/Button';

type Props = {
  selectedLocale: Locale;
  navigationItems: NavigationItem[];
  error?: HttpError;
  isSecondIllustration?: boolean;
};

type FormInputs = {
  email: string;
};

const Footer = ({
  selectedLocale,
  navigationItems,
  error,
  isSecondIllustration,
}: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const INPUTS = [
    {
      name: 'email',
      placeholder: translate(intl, 'UPPER_FOOTER.NEWSLETTER_EMAIL'),
      type: 'text',
      validation: [
        {
          type: 'email',
        },
        {
          type: 'minLength',
          parameter: 4,
        },
        {
          type: 'maxLength',
          parameter: 60,
        },
      ],
    },
  ];

  const handleSubmit = async (submitInputs: FormInputs) => {};

  const {
    inputs,
    onSubmit,
    onInputChange,
    onLoseInputFocus,
    onSetValidationErrors,
    onInputBlur,
  } = useForm<FormInputs>(INPUTS, handleSubmit);

  useEffect(() => {
    if (error) {
      onSetValidationErrors(error);
    }
  }, [error]);

  return (
    <>
      <div className={styles.upperFooterContainer}>
        <div className={styles.illustrationContainer}>
          <img
            src={!isSecondIllustration ? illustration : illustration2}
            alt=""
          />
        </div>
        <div className={styles.newsletterContainer}>
          <div className={styles.newsletterContent}>
            <div className={styles.newsletterTitle}>
              {translate(intl, 'UPPER_FOOTER.TITLE')}
            </div>
            <div className={styles.newsletterFormAndButtonContainer}>
              <Form
                className={styles.form}
                error={error}
                onSubmit={onSubmit}
                scrollIntoView={false}
              >
                {inputs.map((input) => (
                  <TextField
                    key={input.name}
                    onChange={onInputChange}
                    value={input.value?.toString() ?? ''}
                    placeholder={input.placeholder ?? ''}
                    errors={input.validationErrors ?? []}
                    onBlur={onLoseInputFocus}
                    name={input.name}
                    type={input.type}
                    onInputBlur={onInputBlur}
                  />
                ))}
              </Form>
              <Button
                isLoadingButton
                onClick={onSubmit}
                buttonVariant="contained"
                color="primary"
                type="submit"
              >
                {translate(intl, 'UPPER_FOOTER.NEWSLETTER_SUBMIT')}
              </Button>
            </div>
            <div className={styles.newsletterInfo}>
              <div className={cx(styles.columnInfo, styles.workingHoursColumn)}>
                <div className={styles.columnIcon}>
                  <ReactSVG className={styles.icon} src={workingHoursIcon} />
                </div>
                <div className={styles.columnItems}>
                  <div className={styles.columnTitle}>
                    {translate(intl, 'UPPER_FOOTER.WORKING_HOURS_TITLE')}
                  </div>
                  <div className={styles.columnItem}>
                    {translate(intl, 'UPPER_FOOTER.WORKING_HOURS_CONTENT_1')}
                  </div>
                  <div className={styles.columnItem}>
                    {translate(intl, 'UPPER_FOOTER.WORKING_HOURS_CONTENT_2')}
                  </div>
                </div>
              </div>
              <div className={styles.columnInfo}>
                <div className={styles.columnIcon}>
                  <ReactSVG className={styles.icon} src={addressIcon} />
                </div>
                <div className={styles.columnItems}>
                  <div className={styles.columnTitle}>
                    {translate(intl, 'UPPER_FOOTER.ADDRESS_TITLE')}
                  </div>
                  <div className={styles.columnItem}>
                    {translate(intl, 'UPPER_FOOTER.ADDRESS_CONTENT')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerContainer}>
        <div className={styles.footerContent}>
          <div className={styles.leftContent}>
            <Link to={getLocaleUrl(routes.homepage, selectedLocale)}>
              <img src={logo} alt="" />
            </Link>
            <div className={styles.description}>
              {translate(intl, 'FOOTER.DESCRIPTION')}
            </div>
          </div>
          <div className={styles.rightContent}>
            <div className={cx(styles.columnInfo, styles.navigationColumn)}>
              <div className={styles.columnItems}>
                {navigationItems.map((navigationItem, index) => (
                  <div
                    className={cx(styles.columnItem, styles.navigationItem)}
                    key={`${navigationItem.to}-${index}`}
                    onClick={() =>
                      navigate(
                        navigationItem.to,
                        navigationItem.state
                          ? { state: navigationItem.state }
                          : undefined,
                      )
                    }
                  >
                    {navigationItem.label}
                  </div>
                ))}
              </div>
            </div>
            <div className={cx(styles.columnInfo, styles.contactColumn)}>
              <div className={styles.columnItems}>
                <a
                  className={cx(styles.columnItem, styles.phoneNumber)}
                  href={`tel:${translate(intl, 'FOOTER.PHONE_NUMBER').replace(
                    /\s/g,
                    '',
                  )}`}
                >
                  {translate(intl, 'FOOTER.PHONE_NUMBER')}
                </a>
                <a
                  className={cx(styles.columnItem, styles.email)}
                  href={`mailto:${translate(intl, 'FOOTER.EMAIL')}`}
                >
                  {translate(intl, 'FOOTER.EMAIL')}
                </a>
                <div className={styles.socials}>
                  <a
                    href="https://www.facebook.com/BlumeliaRealEstate/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <ReactSVG
                      className={styles.socialIcon}
                      src={iconFacebook}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/blumelia_real_estate/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <ReactSVG
                      className={styles.socialIcon}
                      src={iconInstagram}
                    />
                  </a>
                  <a
                    href="https://es.linkedin.com/company/blumelia"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <ReactSVG
                      className={styles.socialIcon}
                      src={iconLinkedin}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
});

export default connect(mapStateToProps)(Footer);
