import * as actionTypes from './actionTypes';
import { PropertyActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Property } from '../../domain/Property';

export type PropertyStateType = {
  propertiesList: ListResults<Property> | null;
  propertiesListLoading: boolean;
  propertiesListError: HttpError;
  propertiesListUpdateNeeded: boolean;
  propertyCreateLoading: boolean;
  propertyCreateError: HttpError;
  propertyCreateSuccess: boolean;
  createdProperty: Property | null;
  propertyUpdateLoading: boolean;
  propertyUpdateError: HttpError;
  propertyUpdateSuccess: boolean;
  property: Property | null;
  propertyLoading: boolean;
  propertyError: HttpError;
  propertyDeleteLoading: boolean;
  propertyDeleteError: HttpError;
  propertyDeleteSuccess: boolean;
  allProperties: Property[];
  allPropertiesLoading: boolean;
  allPropertiesError: HttpError;
  buildingTypeProperty: Property | null;
  buildingTypePropertyLoading: boolean;
  buildingTypePropertyError: HttpError;
};

export type PropertyActionType = PropertyStateType & {
  type: PropertyActionTypes;
};

export const initialState: PropertyStateType = {
  propertiesList: null,
  propertiesListLoading: true,
  propertiesListError: null,
  propertiesListUpdateNeeded: false,
  propertyCreateLoading: false,
  propertyCreateError: null,
  propertyCreateSuccess: false,
  createdProperty: null,
  propertyUpdateLoading: false,
  propertyUpdateError: null,
  propertyUpdateSuccess: false,
  property: null,
  propertyLoading: false,
  propertyError: null,
  propertyDeleteLoading: false,
  propertyDeleteError: null,
  propertyDeleteSuccess: false,
  allProperties: [],
  allPropertiesLoading: false,
  allPropertiesError: null,
  buildingTypeProperty: null,
  buildingTypePropertyLoading: false,
  buildingTypePropertyError: null,
};

const fetchPropertiesListStart = (
  state: PropertyStateType,
): PropertyStateType => ({
  ...state,
  propertiesListLoading: true,
});

const fetchPropertiesListSuccess = (
  state: PropertyStateType,
  action: PropertyActionType,
): PropertyStateType => ({
  ...state,
  propertiesListLoading: false,
  propertiesList: action.propertiesList,
  propertiesListUpdateNeeded: false,
});

const fetchPropertiesListFail = (
  state: PropertyStateType,
  action: PropertyActionType,
): PropertyStateType => ({
  ...state,
  propertiesListLoading: false,
  propertiesListError: action.propertiesListError,
});

const fetchPropertyStart = (state: PropertyStateType): PropertyStateType => ({
  ...state,
  propertyLoading: true,
});

const fetchPropertySuccess = (
  state: PropertyStateType,
  action: PropertyActionType,
): PropertyStateType => ({
  ...state,
  propertyLoading: false,
  property: action.property,
});

const fetchPropertyFail = (
  state: PropertyStateType,
  action: PropertyActionType,
): PropertyStateType => ({
  ...state,
  propertyLoading: false,
  propertyError: action.propertyError,
});

const createPropertyStart = (state: PropertyStateType): PropertyStateType => ({
  ...state,
  propertyCreateLoading: true,
});

const createPropertySuccess = (
  state: PropertyStateType,
  action: PropertyActionType,
): PropertyStateType => ({
  ...state,
  propertyCreateLoading: false,
  propertyCreateError: null,
  propertyCreateSuccess: true,
  propertiesListUpdateNeeded: true,
  createdProperty: action.createdProperty,
  propertiesList: state.propertiesList
    ? {
        total: state.propertiesList.total + 1,
        result: [
          ...state.propertiesList.result,
          action.createdProperty,
        ] as Property[],
      }
    : null,
});

const createPropertyFail = (
  state: PropertyStateType,
  action: PropertyActionType,
): PropertyStateType => ({
  ...state,
  propertyCreateLoading: false,
  propertyCreateError: action.propertyCreateError,
});

const updatePropertyStart = (state: PropertyStateType): PropertyStateType => ({
  ...state,
  propertyUpdateLoading: true,
});

const updatePropertySuccess = (
  state: PropertyStateType,
): PropertyStateType => ({
  ...state,
  propertyUpdateLoading: false,
  propertyUpdateSuccess: true,
  propertiesListUpdateNeeded: true,
});

const updatePropertyFail = (
  state: PropertyStateType,
  action: PropertyActionType,
): PropertyStateType => ({
  ...state,
  propertyUpdateLoading: false,
  propertyUpdateError: action.propertyUpdateError,
});

const deletePropertyStart = (state: PropertyStateType): PropertyStateType => ({
  ...state,
  propertyDeleteLoading: true,
});

const deletePropertySuccess = (
  state: PropertyStateType,
): PropertyStateType => ({
  ...state,
  propertyDeleteLoading: false,
  propertyDeleteSuccess: true,
  propertiesListUpdateNeeded: true,
});

const deletePropertyFail = (
  state: PropertyStateType,
  action: PropertyActionType,
): PropertyStateType => ({
  ...state,
  propertyDeleteLoading: false,
  propertyDeleteError: action.propertyDeleteError,
});

const resetCreatedPropertyStore = (
  state: PropertyStateType,
): PropertyStateType => ({
  ...state,
  propertyCreateLoading: false,
  propertyCreateError: null,
  propertyUpdateError: null,
  propertyCreateSuccess: false,
  createdProperty: null,
});

const fetchAllPropertiesStart = (
  state: PropertyStateType,
): PropertyStateType => ({
  ...state,
  allPropertiesLoading: true,
});

const fetchAllPropertiesSuccess = (
  state: PropertyStateType,
  action: PropertyActionType,
): PropertyStateType => ({
  ...state,
  allPropertiesLoading: false,
  allProperties: action.allProperties,
});

const fetchAllPropertiesFail = (
  state: PropertyStateType,
  action: PropertyActionType,
): PropertyStateType => ({
  ...state,
  allPropertiesLoading: false,
  allPropertiesError: action.allPropertiesError,
});

const fetchBuildingTypePropertyStart = (
  state: PropertyStateType,
): PropertyStateType => ({
  ...state,
  buildingTypePropertyLoading: true,
});

const fetchBuildingTypePropertySuccess = (
  state: PropertyStateType,
  action: PropertyActionType,
): PropertyStateType => ({
  ...state,
  buildingTypePropertyLoading: false,
  buildingTypeProperty: action.buildingTypeProperty,
});

const fetchBuildingTypePropertyFail = (
  state: PropertyStateType,
  action: PropertyActionType,
): PropertyStateType => ({
  ...state,
  buildingTypePropertyLoading: false,
  buildingTypePropertyError: action.buildingTypePropertyError,
});

const resetPropertyStore = (): PropertyStateType => ({
  ...initialState,
});

const completelyResetPropertyStore = (): PropertyStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: PropertyActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PROPERTIES_LIST_START:
      return fetchPropertiesListStart(state);
    case actionTypes.FETCH_PROPERTIES_LIST_SUCCESS:
      return fetchPropertiesListSuccess(state, action);
    case actionTypes.FETCH_PROPERTIES_LIST_FAIL:
      return fetchPropertiesListFail(state, action);
    case actionTypes.FETCH_PROPERTY_START:
      return fetchPropertyStart(state);
    case actionTypes.FETCH_PROPERTY_SUCCESS:
      return fetchPropertySuccess(state, action);
    case actionTypes.FETCH_PROPERTY_FAIL:
      return fetchPropertyFail(state, action);
    case actionTypes.CREATE_PROPERTY_START:
      return createPropertyStart(state);
    case actionTypes.CREATE_PROPERTY_SUCCESS:
      return createPropertySuccess(state, action);
    case actionTypes.CREATE_PROPERTY_FAIL:
      return createPropertyFail(state, action);
    case actionTypes.UPDATE_PROPERTY_START:
      return updatePropertyStart(state);
    case actionTypes.UPDATE_PROPERTY_SUCCESS:
      return updatePropertySuccess(state);
    case actionTypes.UPDATE_PROPERTY_FAIL:
      return updatePropertyFail(state, action);
    case actionTypes.DELETE_PROPERTY_START:
      return deletePropertyStart(state);
    case actionTypes.DELETE_PROPERTY_SUCCESS:
      return deletePropertySuccess(state);
    case actionTypes.DELETE_PROPERTY_FAIL:
      return deletePropertyFail(state, action);
    case actionTypes.FETCH_ALL_PROPERTIES_START:
      return fetchAllPropertiesStart(state);
    case actionTypes.FETCH_ALL_PROPERTIES_SUCCESS:
      return fetchAllPropertiesSuccess(state, action);
    case actionTypes.FETCH_ALL_PROPERTIES_FAIL:
      return fetchAllPropertiesFail(state, action);
    case actionTypes.FETCH_BUILDING_TYPE_PROPERTY_START:
      return fetchBuildingTypePropertyStart(state);
    case actionTypes.FETCH_BUILDING_TYPE_PROPERTY_SUCCESS:
      return fetchBuildingTypePropertySuccess(state, action);
    case actionTypes.FETCH_BUILDING_TYPE_PROPERTY_FAIL:
      return fetchBuildingTypePropertyFail(state, action);
    case actionTypes.RESET_PROPERTY_STORE:
      return resetPropertyStore();
    case actionTypes.RESET_CREATED_PROPERTY_STORE:
      return resetCreatedPropertyStore(state);
    case actionTypes.LOGOUT:
      return completelyResetPropertyStore();
    default:
      return state;
  }
};

export default reducer;
