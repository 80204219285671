export const FETCH_ALL_PROPERTY_OPTIONS_START =
  'FETCH_ALL_PROPERTY_OPTIONS_START';
export const FETCH_ALL_PROPERTY_OPTIONS_SUCCESS =
  'FETCH_ALL_PROPERTY_OPTIONS_SUCCESS';
export const FETCH_ALL_PROPERTY_OPTIONS_FAIL =
  'FETCH_ALL_PROPERTY_OPTIONS_FAIL';

export const RESET_PROPERTY_OPTION_STORE = 'RESET_PROPERTY_STORE';

export const LOGOUT = 'LOGOUT';

export type PropertyOptionActionTypes =
  | typeof FETCH_ALL_PROPERTY_OPTIONS_START
  | typeof FETCH_ALL_PROPERTY_OPTIONS_SUCCESS
  | typeof FETCH_ALL_PROPERTY_OPTIONS_FAIL
  | typeof RESET_PROPERTY_OPTION_STORE
  | typeof LOGOUT;
