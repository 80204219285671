export const FETCH_PUBLIC_GOOGLE_REVIEWS_START =
  'FETCH_PUBLIC_GOOGLE_REVIEWS_START';
export const FETCH_PUBLIC_GOOGLE_REVIEWS_SUCCESS =
  'FETCH_PUBLIC_GOOGLE_REVIEWS_SUCCESS';
export const FETCH_PUBLIC_GOOGLE_REVIEWS_FAIL =
  'FETCH_PUBLIC_GOOGLE_REVIEWS_FAIL';

export const RESET_GOOGLE_REVIEW_STORE = 'RESET_GUIDE_STORE';

export const LOGOUT = 'LOGOUT';

export type GoogleReviewActionTypes =
  | typeof FETCH_PUBLIC_GOOGLE_REVIEWS_START
  | typeof FETCH_PUBLIC_GOOGLE_REVIEWS_SUCCESS
  | typeof FETCH_PUBLIC_GOOGLE_REVIEWS_FAIL
  | typeof RESET_GOOGLE_REVIEW_STORE
  | typeof LOGOUT;
