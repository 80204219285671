export const FETCH_ENQUIRIES_LIST_START = 'FETCH_ENQUIRIES_LIST_START';
export const FETCH_ENQUIRIES_LIST_SUCCESS = 'FETCH_ENQUIRIES_LIST_SUCCESS';
export const FETCH_ENQUIRIES_LIST_FAIL = 'FETCH_ENQUIRIES_LIST_FAIL';

export const CREATE_ENQUIRY_START = 'CREATE_ENQUIRY_START';
export const CREATE_ENQUIRY_SUCCESS = 'CREATE_ENQUIRY_SUCCESS';
export const CREATE_ENQUIRY_FAIL = 'CREATE_ENQUIRY_FAIL';

export const CREATE_PROPERTY_ENQUIRY_START = 'CREATE_PROPERTY_ENQUIRY_START';
export const CREATE_PROPERTY_ENQUIRY_SUCCESS =
  'CREATE_PROPERTY_ENQUIRY_SUCCESS';
export const CREATE_PROPERTY_ENQUIRY_FAIL = 'CREATE_PROPERTY_ENQUIRY_FAIL';

export const RESET_ENQUIRY_STORE = 'RESET_ENQUIRY_STORE';

export const LOGOUT = 'LOGOUT';

export type EnquiryActionTypes =
  | typeof FETCH_ENQUIRIES_LIST_START
  | typeof FETCH_ENQUIRIES_LIST_SUCCESS
  | typeof FETCH_ENQUIRIES_LIST_FAIL
  | typeof CREATE_ENQUIRY_START
  | typeof CREATE_ENQUIRY_SUCCESS
  | typeof CREATE_ENQUIRY_FAIL
  | typeof CREATE_PROPERTY_ENQUIRY_START
  | typeof CREATE_PROPERTY_ENQUIRY_SUCCESS
  | typeof CREATE_PROPERTY_ENQUIRY_FAIL
  | typeof RESET_ENQUIRY_STORE
  | typeof LOGOUT;
