export const FETCH_TOWN_AREAS_LIST_START = 'FETCH_TOWN_AREAS_LIST_START';
export const FETCH_TOWN_AREAS_LIST_SUCCESS = 'FETCH_TOWN_AREAS_LIST_SUCCESS';
export const FETCH_TOWN_AREAS_LIST_FAIL = 'FETCH_TOWN_AREAS_LIST_FAIL';

export const FETCH_ALL_TOWN_AREAS_OPTIONS_START =
  'FETCH_ALL_TOWN_AREAS_OPTIONS_START';
export const FETCH_ALL_TOWN_AREAS_OPTIONS_SUCCESS =
  'FETCH_ALL_TOWN_AREAS_OPTIONS_SUCCESS';
export const FETCH_ALL_TOWN_AREAS_OPTIONS_FAIL =
  'FETCH_ALL_TOWN_AREAS_OPTIONS_FAIL';

export const FETCH_TOWN_AREA_START = 'FETCH_TOWN_AREA_START';
export const FETCH_TOWN_AREA_SUCCESS = 'FETCH_TOWN_AREA_SUCCESS';
export const FETCH_TOWN_AREA_FAIL = 'FETCH_TOWN_AREA_FAIL';

export const CREATE_TOWN_AREA_START = 'CREATE_TOWN_AREA_START';
export const CREATE_TOWN_AREA_SUCCESS = 'CREATE_TOWN_AREA_SUCCESS';
export const CREATE_TOWN_AREA_FAIL = 'CREATE_TOWN_AREA_FAIL';

export const UPDATE_TOWN_AREA_START = 'UPDATE_TOWN_AREA_START';
export const UPDATE_TOWN_AREA_SUCCESS = 'UPDATE_TOWN_AREA_SUCCESS';
export const UPDATE_TOWN_AREA_FAIL = 'UPDATE_TOWN_AREA_FAIL';

export const DELETE_TOWN_AREA_START = 'DELETE_TOWN_AREA_START';
export const DELETE_TOWN_AREA_SUCCESS = 'DELETE_TOWN_AREA_SUCCESS';
export const DELETE_TOWN_AREA_FAIL = 'DELETE_TOWN_AREA_FAIL';

export const RESET_CREATED_TOWN_AREA_STORE = 'RESET_CREATED_TOWN_AREA';
export const RESET_TOWN_AREA_STORE = 'RESET_TOWN_AREA_STORE';

export const LOGOUT = 'LOGOUT';

export type TownAreaActionTypes =
  | typeof FETCH_TOWN_AREAS_LIST_START
  | typeof FETCH_TOWN_AREAS_LIST_SUCCESS
  | typeof FETCH_TOWN_AREAS_LIST_FAIL
  | typeof FETCH_ALL_TOWN_AREAS_OPTIONS_START
  | typeof FETCH_ALL_TOWN_AREAS_OPTIONS_SUCCESS
  | typeof FETCH_ALL_TOWN_AREAS_OPTIONS_FAIL
  | typeof FETCH_TOWN_AREA_START
  | typeof FETCH_TOWN_AREA_SUCCESS
  | typeof FETCH_TOWN_AREA_FAIL
  | typeof CREATE_TOWN_AREA_START
  | typeof CREATE_TOWN_AREA_SUCCESS
  | typeof CREATE_TOWN_AREA_FAIL
  | typeof UPDATE_TOWN_AREA_START
  | typeof UPDATE_TOWN_AREA_SUCCESS
  | typeof UPDATE_TOWN_AREA_FAIL
  | typeof DELETE_TOWN_AREA_START
  | typeof DELETE_TOWN_AREA_SUCCESS
  | typeof DELETE_TOWN_AREA_FAIL
  | typeof RESET_CREATED_TOWN_AREA_STORE
  | typeof RESET_TOWN_AREA_STORE
  | typeof LOGOUT;
