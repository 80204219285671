import React from 'react';
import { FormattedNumber } from 'react-intl';

type Props = {
  price: number;
};

export const FormattedPrice = ({ price }: Props) => {
  return (
    <FormattedNumber
      value={price}
      currency="EUR"
      style="currency"
      minimumFractionDigits={0}
      maximumFractionDigits={0}
    />
  );
};
