export const FETCH_INTEGRATION_LIST_START = 'FETCH_INTEGRATION_LIST_START';
export const FETCH_INTEGRATION_LIST_SUCCESS = 'FETCH_INTEGRATION_LIST_SUCCESS';
export const FETCH_INTEGRATION_LIST_FAIL = 'FETCH_INTEGRATION_LIST_FAIL';

export const TOGGLE_INTEGRATION_START = 'TOGGLE_INTEGRATION_START';
export const TOGGLE_INTEGRATION_SUCCESS = 'TOGGLE_INTEGRATION_SUCCESS';
export const TOGGLE_INTEGRATION_FAIL = 'TOGGLE_INTEGRATION_FAIL';

export const FETCH_ALL_INTEGRATIONS_START = 'FETCH_ALL_INTEGRATIONS_START';
export const FETCH_ALL_INTEGRATIONS_SUCCESS = 'FETCH_ALL_INTEGRATIONS_SUCCESS';
export const FETCH_ALL_INTEGRATIONS_FAIL = 'FETCH_ALL_INTEGRATIONS_FAIL';

export const RESET_INTEGRATION_STORE = 'RESET_INTEGRATION_STORE';

export const LOGOUT = 'LOGOUT';

export type IntegrationActionTypes =
  | typeof FETCH_INTEGRATION_LIST_START
  | typeof FETCH_INTEGRATION_LIST_SUCCESS
  | typeof FETCH_INTEGRATION_LIST_FAIL
  | typeof TOGGLE_INTEGRATION_START
  | typeof TOGGLE_INTEGRATION_SUCCESS
  | typeof TOGGLE_INTEGRATION_FAIL
  | typeof FETCH_ALL_INTEGRATIONS_START
  | typeof FETCH_ALL_INTEGRATIONS_SUCCESS
  | typeof FETCH_ALL_INTEGRATIONS_FAIL
  | typeof RESET_INTEGRATION_STORE
  | typeof LOGOUT;
