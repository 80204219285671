import * as actionTypes from './actionTypes';
import { GoogleReviewActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { GoogleReview } from 'src/domain/GoogleReview';

export type GoogleReviewStateType = {
  publicGoogleReviews: GoogleReview[];
  publicGoogleReviewsLoading: boolean;
  publicGoogleReviewsError: HttpError;
};

export type GoogleReviewActionType = GoogleReviewStateType & {
  type: GoogleReviewActionTypes;
};

export const initialState: GoogleReviewStateType = {
  publicGoogleReviews: [],
  publicGoogleReviewsLoading: true,
  publicGoogleReviewsError: null,
};

const fetchPublicReviewsStart = (
  state: GoogleReviewStateType,
): GoogleReviewStateType => ({
  ...state,
  publicGoogleReviewsLoading: true,
});

const fetchPublicReviewsSuccess = (
  state: GoogleReviewStateType,
  action: GoogleReviewActionType,
): GoogleReviewStateType => ({
  ...state,
  publicGoogleReviewsLoading: false,
  publicGoogleReviews: action.publicGoogleReviews,
});

const fetchPublicReviewsFail = (
  state: GoogleReviewStateType,
  action: GoogleReviewActionType,
): GoogleReviewStateType => ({
  ...state,
  publicGoogleReviewsLoading: false,
  publicGoogleReviewsError: action.publicGoogleReviewsError,
});

const resetGoogleReviewStore = (): GoogleReviewStateType => ({
  ...initialState,
});

const completelyResetGoogleReviewStore = (): GoogleReviewStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: GoogleReviewActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PUBLIC_GOOGLE_REVIEWS_START:
      return fetchPublicReviewsStart(state);
    case actionTypes.FETCH_PUBLIC_GOOGLE_REVIEWS_SUCCESS:
      return fetchPublicReviewsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_GOOGLE_REVIEWS_FAIL:
      return fetchPublicReviewsFail(state, action);
    case actionTypes.RESET_GOOGLE_REVIEW_STORE:
      return resetGoogleReviewStore();
    case actionTypes.LOGOUT:
      return completelyResetGoogleReviewStore();
    default:
      return state;
  }
};

export default reducer;
