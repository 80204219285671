export const FETCH_PRICE_NOTIFICATIONS_LIST_START =
  'FETCH_PRICE_NOTIFICATIONS_LIST_START';
export const FETCH_PRICE_NOTIFICATIONS_LIST_SUCCESS =
  'FETCH_PRICE_NOTIFICATIONS_LIST_SUCCESS';
export const FETCH_PRICE_NOTIFICATIONS_LIST_FAIL =
  'FETCH_PRICE_NOTIFICATIONS_LIST_FAIL';

export const UPDATE_PRICE_NOTIFICATION_IS_SEEN_STATUS_START =
  'UPDATE_PRICE_NOTIFICATION_IS_SEEN_STATUS_START';
export const UPDATE_PRICE_NOTIFICATION_IS_SEEN_STATUS_SUCCESS =
  'UPDATE_PRICE_NOTIFICATION_IS_SEEN_STATUS_SUCCESS';
export const UPDATE_PRICE_NOTIFICATION_IS_SEEN_STATUS_FAIL =
  'UPDATE_PRICE_NOTIFICATION_IS_SEEN_STATUS_FAIL';

export const RESET_PRICE_NOTIFICATION_STORE = 'RESET_PRICE_NOTIFICATION_STORE';

export type PriceNotificationActionTypes =
  | typeof FETCH_PRICE_NOTIFICATIONS_LIST_START
  | typeof FETCH_PRICE_NOTIFICATIONS_LIST_SUCCESS
  | typeof FETCH_PRICE_NOTIFICATIONS_LIST_FAIL
  | typeof UPDATE_PRICE_NOTIFICATION_IS_SEEN_STATUS_START
  | typeof UPDATE_PRICE_NOTIFICATION_IS_SEEN_STATUS_SUCCESS
  | typeof UPDATE_PRICE_NOTIFICATION_IS_SEEN_STATUS_FAIL
  | typeof RESET_PRICE_NOTIFICATION_STORE;
