import { ChangeEvent, ReactNode, useState } from 'react';
import {
  getValidationError,
  ValidationRule,
} from '../../utility/validation/validation';
import _, { trim } from 'lodash';
import { HttpError } from '../../config/Axios/axios-instance';
import { Asset } from '../../domain/Asset';
import { useIntl } from 'react-intl';
import { EntityTranslation } from '../../domain/EntityTranslation';

const IGNORED_TRIM = [
  'password',
  'file',
  'autocomplete',
  'custom',
  'upload',
  'translations',
];

export type LabelPlacement = 'end' | 'start' | 'top' | 'bottom';

export type FormInputBlueprint = {
  name: string;
  label?: ReactNode;
  type: string;
  validation?: Array<ValidationRule>;
  value?: string | string[] | number | File | File[] | null | any[];
  isHidden?: boolean;
  options?: Array<any>;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  capitalize?: boolean;
  locale?: string;
  disableClearable?: boolean;
  multiple?: boolean;
  labelPlacement?: LabelPlacement;
  alternativeNames?: string[];
  asset?: Asset | Asset[] | null;
  creatable?: boolean;
  maxFileCount?: number;
  freeSolo?: boolean;
  multipleTranslations?: MultipleTranslations;
  setMultipleTranslations?: SetMultipleTranslations;
  multipleTranslationsForInput?: string;
  isRequired?: boolean;
  headerTitle?: string;
};

export type MultipleTranslations = {
  [key: string | number]: EntityTranslation[];
};

export type SetMultipleTranslations = React.Dispatch<
  React.SetStateAction<MultipleTranslations>
>;

export type FormInput = FormInputBlueprint & {
  isValidatable?: boolean;
  validationErrors?: Array<string>;
};

export type FormBehavior = {
  submitOnChange?: boolean;
};

export type FormSubmitInput = {
  [key: string]: string;
};

export const useForm = <T>(
  inputBlueprints: Array<FormInputBlueprint>,
  onFormSubmit?: (inputs: T) => void,
  formBehavior?: FormBehavior,
) => {
  const intl = useIntl();

  const [inputs, setInputs] = useState<Array<FormInput>>(inputBlueprints);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    setInputs((prevState) => {
      const inputs = prevState.map((prevInput) => {
        return prevInput.name === event.target.name
          ? {
              ...prevInput,
              value: prevInput.capitalize
                ? event.target.value.toUpperCase()
                : event.target.value,
              validationErrors: prevInput.isValidatable
                ? getValidationError(
                    event.target.value,
                    prevInput.validation,
                    intl,
                  )
                : [],
            }
          : { ...prevInput };
      });

      formBehavior?.submitOnChange &&
        onFormSubmit &&
        onFormSubmit(getSubmitInputs(inputs));

      return inputs;
    });
  };

  const onCheckboxChange = (name: string, value: string) => {
    setInputs((prevState) => {
      const inputs = prevState.map((prevInput) => {
        return prevInput.name === name
          ? {
              ...prevInput,
              value: value,
              validationErrors: prevInput.isValidatable
                ? getValidationError(value, prevInput.validation, intl)
                : [],
            }
          : { ...prevInput };
      });

      formBehavior?.submitOnChange &&
        onFormSubmit &&
        onFormSubmit(getSubmitInputs(inputs));

      return inputs;
    });
  };

  const onInputValueChange = (name: string, value: string) => {
    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              value: value,
              validationErrors: prevInput.isValidatable
                ? getValidationError(value, prevInput.validation, intl)
                : [],
            }
          : { ...prevInput },
      ),
    );
  };

  const onTranslationsChange = (name: string, value: EntityTranslation[]) => {
    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              value: value,
              validationErrors: onTranslationValidation(prevInput),
            }
          : { ...prevInput },
      ),
    );
  };

  const onTimeChange = (name: string, value: string) => {
    setInputs((prevState) => {
      const inputs = prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              value: value,
              validationErrors: prevInput.isValidatable
                ? getValidationError(value, prevInput.validation, intl)
                : [],
            }
          : { ...prevInput },
      );

      formBehavior?.submitOnChange &&
        onFormSubmit &&
        onFormSubmit(getSubmitInputs(inputs));

      return inputs;
    });
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const files = event.target.files ? Array.from(event.target.files) : [];

    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === event.target.name
          ? {
              ...prevInput,
              value:
                prevInput.multiple && Array.isArray(prevInput.value)
                  ? [...prevInput.value, ...files]
                  : files[0],
              isValidatable: true,
              validationErrors: files.length
                ? getValidationError(
                    prevInput.multiple ? files : files[0],
                    prevInput.validation,
                    intl,
                  )
                : [],
            }
          : { ...prevInput },
      ),
    );
  };

  const onFileDropped = (name: string, inputfiles: File[]) => {
    const files = inputfiles ? Array.from(inputfiles) : [];

    setInputs((prevState) => {
      const inputs = prevState.map((prevInput) => {
        return prevInput.name === name
          ? {
              ...prevInput,
              value:
                prevInput.multiple && Array.isArray(prevInput.value)
                  ? [...prevInput.value, ...files]
                  : files[0],
              isValidatable: true,
              validationErrors: files.length
                ? getValidationError(
                    prevInput.multiple ? files : files[0],
                    prevInput.validation,
                    intl,
                  )
                : [],
            }
          : { ...prevInput };
      });

      formBehavior?.submitOnChange &&
        onFormSubmit &&
        onFormSubmit(getSubmitInputs(inputs));

      return inputs;
    });
  };

  const onSelectChange = (
    value: string | string[] | any[],
    name: string,
    freeSolo?: boolean,
  ) => {
    setInputs((prevState) => {
      const inputs = prevState.map((prevInput) => {
        const getOptions = () => {
          const optionLength = value.length - 1;

          const optionExists = prevInput.options?.find(
            (option) => option.value === value[optionLength],
          );

          if (optionExists) {
            return prevInput?.options ? [...prevInput.options] : undefined;
          }

          if (freeSolo) {
            return prevInput.options && value.length > 0
              ? [
                  ...prevInput.options,
                  { value: value[optionLength], label: value[optionLength] },
                ]
              : [];
          }

          return prevInput.options ? [...prevInput.options] : undefined;
        };

        return prevInput.name === name
          ? {
              ...prevInput,
              value: value,
              options: getOptions(),
              validationErrors: prevInput.isValidatable
                ? getValidationError(value, prevInput.validation, intl)
                : [],
            }
          : { ...prevInput };
      });

      formBehavior?.submitOnChange &&
        onFormSubmit &&
        onFormSubmit(getSubmitInputs(inputs));

      return inputs;
    });
  };

  const onClearInput = (name: string) => {
    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              value: '',
              validationErrors: prevInput.isValidatable
                ? getValidationError('', prevInput.validation, intl)
                : [],
            }
          : { ...prevInput },
      ),
    );
  };

  const onLoseInputFocus = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === event.target.name
          ? {
              ...prevInput,
              isValidatable: true,
              validationErrors: getValidationError(
                prevInput.value instanceof File
                  ? ''
                  : (IGNORED_TRIM.includes(prevInput.type)
                      ? prevInput.value
                      : trim(prevInput.value?.toString())) || '',
                prevInput.validation,
                intl,
              ),
            }
          : { ...prevInput },
      ),
    );
  };

  const onInputBlur = (name: string) => {
    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              isValidatable: true,
              validationErrors:
                prevInput.type === 'translations'
                  ? onTranslationValidation(prevInput)
                  : getValidationError(
                      (IGNORED_TRIM.includes(prevInput.type)
                        ? prevInput.value
                        : trim(prevInput.value?.toString())) || '',
                      prevInput.validation,
                      intl,
                    ),
            }
          : { ...prevInput },
      ),
    );
  };

  const setNewInputObject = (name: string, newValue: any) => {
    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              ...newValue,
            }
          : { ...prevInput },
      ),
    );
  };

  const setNewTranslationsInputObject = (name: string, newValue: any) => {
    setInputs((prevState) =>
      prevState.map((prevInput: any) =>
        prevInput.name === name
          ? {
              ...prevInput,
              value: prevInput?.value?.map((entry: any) =>
                entry.languageId === newValue.languageId &&
                entry.property === newValue.property
                  ? { ...entry, value: newValue.value }
                  : entry,
              ),
            }
          : { ...prevInput },
      ),
    );
  };

  const onSubmit = (event?: ChangeEvent) => {
    event?.preventDefault();

    setInputs(
      inputs.map((input) => ({
        ...input,
        validationErrors: [],
      })),
    );

    const validatedInputs = inputs.map((input) => ({
      ...input,
      validationErrors:
        input.type === 'translations'
          ? onTranslationValidation(input)
          : getValidationError(
              input.value instanceof File
                ? input.value
                : (IGNORED_TRIM.includes(input.type)
                    ? input.value
                    : trim(input.value?.toString())) || '',
              input.validation,
              intl,
            ),
    }));

    if (
      _.find(
        validatedInputs,
        (validatedInput) => !_.isEmpty(validatedInput.validationErrors),
      )
    ) {
      setInputs(validatedInputs);
      return;
    }

    handleSubmit();
  };

  const handleSubmit = () => {
    onFormSubmit &&
      onFormSubmit(
        Object.assign(
          {},
          ...inputs.map((input) => ({
            [input.name]: IGNORED_TRIM.includes(input.type)
              ? input.value
              : trim(input.value?.toString()),
          })),
        ),
      );
  };

  const onFileDelete = (name: string, file: string | File) => {
    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              value:
                prevInput.multiple && Array.isArray(prevInput.value)
                  ? prevInput.value.filter((val: string | File) => {
                      if (file instanceof File && val instanceof File) {
                        return file.name !== val.name;
                      }

                      return val !== file;
                    })
                  : '',
              validationErrors: prevInput.isValidatable
                ? getValidationError('', prevInput.validation, intl)
                : [],
            }
          : { ...prevInput },
      ),
    );
  };

  const getSubmitInputs = (submitInputs: Array<FormInput>) =>
    Object.assign(
      {},
      ...submitInputs.map((input) => ({
        [input.name]:
          IGNORED_TRIM.includes(input.type) || Array.isArray(input.value)
            ? input.value
            : trim(input.value?.toString()),
      })),
    );

  const onSetValidationErrors = (error: HttpError) => {
    if (_.isArray(error)) {
      error.forEach((fieldError) => {
        setInputs((prevState) =>
          prevState.map((prevInput) =>
            prevInput.name === fieldError.field ||
            prevInput.alternativeNames?.includes(fieldError.field)
              ? {
                  ...prevInput,
                  isValidatable: true,
                  validationErrors: [fieldError.message],
                }
              : { ...prevInput },
          ),
        );
      });
    }
  };

  const onTranslationValidation = (translationInput: FormInput): string[] => {
    if (!translationInput) {
      return [];
    }

    let errors: string[] = [];

    for (const translation of translationInput.value as EntityTranslation[]) {
      const defaultInput = inputs.filter(
        (input) => input.name === translation.property,
      )[0];

      if (!defaultInput) {
        continue;
      }

      const inputErrors = getValidationError(
        translation.value,
        defaultInput.validation,
        intl,
      ).map(
        (error) => `${translation.property}_${translation.languageId}:${error}`,
      );

      errors = [...errors, ...inputErrors];
    }

    return errors;
  };

  return {
    inputs,
    onInputChange,
    onCheckboxChange,
    onTimeChange,
    onFileChange,
    onLoseInputFocus,
    onSubmit,
    onClearInput,
    setNewInputObject,
    onSelectChange,
    onFileDelete,
    getSubmitInputs,
    onSetValidationErrors,
    onInputBlur,
    onInputValueChange,
    onTranslationsChange,
    onFileDropped,
    setNewTranslationsInputObject,
  };
};
