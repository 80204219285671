export const FETCH_COMPANY_SETTINGS_START = 'FETCH_COMPANY_SETTINGS_START';
export const FETCH_COMPANY_SETTINGS_SUCCESS = 'FETCH_COMPANY_SETTINGS_SUCCESS';
export const FETCH_COMPANY_SETTINGS_FAIL = 'FETCH_COMPANY_SETTINGS_FAIL';

export const UPDATE_COMPNAY_SETTINGS_START = 'UPDATE_COMPNAY_SETTINGS_START';
export const UPDATE_COMPNAY_SETTINGS_SUCCESS =
  'UPDATE_COMPNAY_SETTINGS_SUCCESS';
export const UPDATE_COMPNAY_SETTINGS_FAIL = 'UPDATE_COMPNAY_SETTINGS_FAIL';

export const RESET_COMPANY_SETTINGS_STORE = 'RESET_COMPANY_SETTINGS_STORE';

export const LOGOUT = 'LOGOUT';

export type CompanySettingsActionTypes =
  | typeof FETCH_COMPANY_SETTINGS_START
  | typeof FETCH_COMPANY_SETTINGS_SUCCESS
  | typeof FETCH_COMPANY_SETTINGS_FAIL
  | typeof UPDATE_COMPNAY_SETTINGS_START
  | typeof UPDATE_COMPNAY_SETTINGS_SUCCESS
  | typeof UPDATE_COMPNAY_SETTINGS_FAIL
  | typeof RESET_COMPANY_SETTINGS_STORE
  | typeof LOGOUT;
