export const GENERATE_LOCATION_TRANSLATIONS_START =
  'GENERATE_LOCATION_TRANSLATIONS_START';
export const GENERATE_LOCATION_TRANSLATIONS_SUCCESS =
  'GENERATE_LOCATION_TRANSLATIONS_SUCCESS';
export const GENERATE_LOCATION_TRANSLATIONS_FAIL =
  'GENERATE_LOCATION_TRANSLATIONS_FAIL';

export const RESET_GENERATED_LOCATION_TRANSLATIONS =
  'RESET_GENERATED_LOCATION_TRANSLATIONS';

export const LOGOUT = 'LOGOUT';

export type DeepLActionTypes =
  | typeof GENERATE_LOCATION_TRANSLATIONS_START
  | typeof GENERATE_LOCATION_TRANSLATIONS_SUCCESS
  | typeof GENERATE_LOCATION_TRANSLATIONS_FAIL
  | typeof RESET_GENERATED_LOCATION_TRANSLATIONS
  | typeof LOGOUT;
